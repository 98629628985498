/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import {
  CreateTransportationOrder,
  TransportationOrderPost,
  TransportationOrder,
  TransportationOrderState,
} from './transportationOrderTypes';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';
import {
  createTransportationOrderAPI,
  deleteTransportationOrderAPI,
  deliveryNoteTransportationOrderAPI,
  listAvailableTransportationOrdersAPI,
  listPendingTransportationOrdersAPI,
  listTransportationOrderLocationsAPI,
  listTransportationOrdersAPI,
  listTransportationOrderStatusAPI,
  listTransportationOrderTransportTypesAPI,
  listTransportationOrderTypesAPI,
  updateTransportationOrderAPI,
} from './transportationOrderAPI';
import {
  ComponentLifecycle, Rejected, ResponseErrorData, FormErrors,
} from '../../constants';

export const initialState: TransportationOrderState = {
  entities: [],
  availableEntities: [],
  pendingEntities: [],
  availableLocations: [],
  availableStatus: [],
  availableTypes: [],
  availableTransportTypes: [],
  listLifecycle: ComponentLifecycle.INITIAL,
  listAvailableLifecycle: ComponentLifecycle.INITIAL,
  listPendingLifecycle: ComponentLifecycle.INITIAL,
  listLocationsLifecycle: ComponentLifecycle.INITIAL,
  listStatusLifecycle: ComponentLifecycle.INITIAL,
  listTypesLifecycle: ComponentLifecycle.INITIAL,
  listTransportTypesLifecycle: ComponentLifecycle.INITIAL,
  createLifecycle: ComponentLifecycle.INITIAL,
  updateLifecycle: {},
  deleteLifecycle: {},
  deliveryNoteLifecycle: ComponentLifecycle.INITIAL,
};

export const listTransportationOrders = createAsyncThunk(
  'list-transportation-orders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listTransportationOrdersAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listAvailableTransportationOrders = createAsyncThunk(
  'list-available-transportation-orders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listAvailableTransportationOrdersAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listPendingTransportationOrders = createAsyncThunk(
  'list-pending-transportation-orders',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listPendingTransportationOrdersAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listAvailableTransportationOrderStatus = createAsyncThunk(
  'list-available-transportation-order-status',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listTransportationOrderStatusAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listAvailableTransportationOrderLocations = createAsyncThunk(
  'list-available-transportation-order-locations',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listTransportationOrderLocationsAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listAvailableTransportationOrderTypes = createAsyncThunk(
  'list-available-transportation-order-types',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listTransportationOrderTypesAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listAvailableTransportationOrderTransportTypes = createAsyncThunk(
  'list-available-transportation-order-transport-types',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listTransportationOrderTransportTypesAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const createTransportationOrder = createAsyncThunk(
  'create-transportation-order',
  async (transportationOrder: CreateTransportationOrder, { rejectWithValue }) => {
    try {
      const response = await createTransportationOrderAPI(transportationOrder);
      return response.data;
    } catch (responseError: any) {
      const { name, message, form_errors } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
        form_errors: form_errors ? JSON.parse(form_errors) as FormErrors : {},
      } as Rejected);
    }
  },
);

export const updateTransportationOrder = createAsyncThunk(
  'update-transportation-order',
  async (transportationOrder: TransportationOrderPost, { rejectWithValue }) => {
    try {
      const response = await updateTransportationOrderAPI(transportationOrder);
      return response.data;
    } catch (responseError: any) {
      const { name, message, form_errors } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
        form_errors: form_errors ? JSON.parse(form_errors) as FormErrors : {},
      } as Rejected);
    }
  },
);

export const deleteTransportationOrder = createAsyncThunk(
  'delete-transportation-order',
  async (transportationOrderId: string, { rejectWithValue }) => {
    try {
      const response = await deleteTransportationOrderAPI(transportationOrderId);
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const deliveryNoteTransportationOrder = createAsyncThunk(
  'delivery-note-transportation-order',
  async (transportationOrderId: string, { rejectWithValue }) => {
    try {
      const response = await deliveryNoteTransportationOrderAPI(transportationOrderId);
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' },
      );
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const resetCreateTransportationOrderLifecycle = createAction('Reset Create Order Lifecycle');
export const resetUpdateTransportationOrderLifecycle = createAction<{id: string}>('Reset Update Order Lifecycle');

const transportationOrdersSlice = createSlice({
  name: 'transportationOrders',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(listTransportationOrders.pending, (state) => {
        state.listLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.entities = [];
      })
      .addCase(listTransportationOrders.fulfilled, (state, action) => {
        state.listLifecycle = ComponentLifecycle.IDLE;
        state.entities = [...action.payload];
      })
      .addCase(listTransportationOrders.rejected, (state, action) => {
        state.listLifecycle = action.payload as Rejected;
      })
      .addCase(listAvailableTransportationOrders.pending, (state) => {
        state.listAvailableLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.availableEntities = [];
      })
      .addCase(listAvailableTransportationOrders.fulfilled, (state, action) => {
        state.listAvailableLifecycle = ComponentLifecycle.IDLE;
        state.availableEntities = [...action.payload];
      })
      .addCase(listAvailableTransportationOrders.rejected, (state, action) => {
        state.listAvailableLifecycle = action.payload as Rejected;
      })
      .addCase(listPendingTransportationOrders.pending, (state) => {
        state.listPendingLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.pendingEntities = [];
      })
      .addCase(listPendingTransportationOrders.fulfilled, (state, action) => {
        state.listPendingLifecycle = ComponentLifecycle.IDLE;
        state.pendingEntities = [...action.payload];
      })
      .addCase(listPendingTransportationOrders.rejected, (state, action) => {
        state.listPendingLifecycle = action.payload as Rejected;
      })
      .addCase(listAvailableTransportationOrderLocations.pending, (state) => {
        state.listLocationsLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.availableLocations = [];
      })
      .addCase(listAvailableTransportationOrderLocations.fulfilled, (state, action) => {
        state.listLocationsLifecycle = ComponentLifecycle.IDLE;
        state.availableLocations = [...action.payload];
      })
      .addCase(listAvailableTransportationOrderLocations.rejected, (state, action) => {
        state.listLocationsLifecycle = action.payload as Rejected;
      })
      .addCase(listAvailableTransportationOrderStatus.pending, (state) => {
        state.listStatusLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.availableStatus = [];
      })
      .addCase(listAvailableTransportationOrderStatus.fulfilled, (state, action) => {
        state.listStatusLifecycle = ComponentLifecycle.IDLE;
        state.availableStatus = [...action.payload];
      })
      .addCase(listAvailableTransportationOrderStatus.rejected, (state, action) => {
        state.listStatusLifecycle = action.payload as Rejected;
      })
      .addCase(listAvailableTransportationOrderTypes.pending, (state) => {
        state.listTypesLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.availableTypes = [];
      })
      .addCase(listAvailableTransportationOrderTypes.fulfilled, (state, action) => {
        state.listTypesLifecycle = ComponentLifecycle.IDLE;
        state.availableTypes = [...action.payload];
      })
      .addCase(listAvailableTransportationOrderTypes.rejected, (state, action) => {
        state.listTypesLifecycle = action.payload as Rejected;
      })
      .addCase(listAvailableTransportationOrderTransportTypes.pending, (state) => {
        state.listTransportTypesLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.availableTransportTypes = [];
      })
      .addCase(listAvailableTransportationOrderTransportTypes.fulfilled, (state, action) => {
        state.listTransportTypesLifecycle = ComponentLifecycle.IDLE;
        state.availableTransportTypes = [...action.payload];
      })
      .addCase(listAvailableTransportationOrderTransportTypes.rejected, (state, action) => {
        state.listTransportTypesLifecycle = action.payload as Rejected;
      })
      .addCase(createTransportationOrder.pending, (state) => {
        state.createLifecycle = ComponentLifecycle.FETCHING_DATA;
      })
      .addCase(createTransportationOrder.fulfilled, (state, action) => {
        state.createLifecycle = ComponentLifecycle.IDLE;
        state.entities.push(action.payload);
        state.availableEntities.push(action.payload);
        state.pendingEntities.push(action.payload);
      })
      .addCase(createTransportationOrder.rejected, (state, action) => {
        state.createLifecycle = action.payload as Rejected;
      })
      .addCase(resetCreateTransportationOrderLifecycle, (state) => {
        state.createLifecycle = ComponentLifecycle.INITIAL;
      })
      .addCase(updateTransportationOrder.pending, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.meta.arg.id]: ComponentLifecycle.FETCHING_DATA,
        };
      })
      .addCase(updateTransportationOrder.fulfilled, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.payload.id]: ComponentLifecycle.IDLE,
        };
        const {
          id,
          license_plate,
          model,
          type,
          name,
          phone,
          notes,
          transport_notes,
          priority,
          delivery_note,
          contact_date,
          availability,
          transport_date,
          transport_type,
          payment_part,
          status,
          location,
          address,
        } = action.payload;
        const existingTransportationOrder = state
          .entities.find((transportationOrder) => transportationOrder.id === id);
        if (existingTransportationOrder) {
          existingTransportationOrder.license_plate = license_plate;
          existingTransportationOrder.model = model;
          existingTransportationOrder.type = type;
          existingTransportationOrder.address.address_line = address.address_line;
          existingTransportationOrder.address.postal_code = address.postal_code;
          existingTransportationOrder.address.locality = address.locality;
          existingTransportationOrder.address.region = address.region;
          existingTransportationOrder.address.country = address.country;
          existingTransportationOrder.name = name;
          existingTransportationOrder.phone = phone;
          existingTransportationOrder.notes = notes;
          existingTransportationOrder.transport_notes = transport_notes;
          existingTransportationOrder.priority = priority;
          existingTransportationOrder.delivery_note = delivery_note;
          existingTransportationOrder.contact_date = contact_date;
          existingTransportationOrder.availability = availability;
          existingTransportationOrder.transport_date = transport_date;
          existingTransportationOrder.transport_type = transport_type;
          existingTransportationOrder.payment_part = payment_part;
          existingTransportationOrder.status = status;
          existingTransportationOrder.location = location;
        }
        const existingAvailableTransportationOrder = state
          .availableEntities.find((transportationOrder) => transportationOrder.id === id);
        if (existingAvailableTransportationOrder) {
          existingAvailableTransportationOrder.license_plate = license_plate;
          existingAvailableTransportationOrder.model = model;
          existingAvailableTransportationOrder.type = type;
          existingAvailableTransportationOrder.address.address_line = address.address_line;
          existingAvailableTransportationOrder.address.postal_code = address.postal_code;
          existingAvailableTransportationOrder.address.locality = address.locality;
          existingAvailableTransportationOrder.address.region = address.region;
          existingAvailableTransportationOrder.address.country = address.country;
          existingAvailableTransportationOrder.name = name;
          existingAvailableTransportationOrder.phone = phone;
          existingAvailableTransportationOrder.notes = notes;
          existingAvailableTransportationOrder.transport_notes = transport_notes;
          existingAvailableTransportationOrder.priority = priority;
          existingAvailableTransportationOrder.delivery_note = delivery_note;
          existingAvailableTransportationOrder.contact_date = contact_date;
          existingAvailableTransportationOrder.availability = availability;
          existingAvailableTransportationOrder.transport_date = transport_date;
          existingAvailableTransportationOrder.transport_type = transport_type;
          existingAvailableTransportationOrder.payment_part = payment_part;
          existingAvailableTransportationOrder.status = status;
          existingAvailableTransportationOrder.location = location;
        }
        const existingPendingTransportationOrder = state
          .pendingEntities.find((transportationOrder) => transportationOrder.id === id);
        if (existingPendingTransportationOrder) {
          existingPendingTransportationOrder.license_plate = license_plate;
          existingPendingTransportationOrder.model = model;
          existingPendingTransportationOrder.type = type;
          existingPendingTransportationOrder.address.address_line = address.address_line;
          existingPendingTransportationOrder.address.postal_code = address.postal_code;
          existingPendingTransportationOrder.address.locality = address.locality;
          existingPendingTransportationOrder.address.region = address.region;
          existingPendingTransportationOrder.address.country = address.country;
          existingPendingTransportationOrder.name = name;
          existingPendingTransportationOrder.phone = phone;
          existingPendingTransportationOrder.notes = notes;
          existingPendingTransportationOrder.transport_notes = transport_notes;
          existingPendingTransportationOrder.priority = priority;
          existingPendingTransportationOrder.delivery_note = delivery_note;
          existingPendingTransportationOrder.contact_date = contact_date;
          existingPendingTransportationOrder.availability = availability;
          existingPendingTransportationOrder.transport_date = transport_date;
          existingPendingTransportationOrder.transport_type = transport_type;
          existingPendingTransportationOrder.payment_part = payment_part;
          existingPendingTransportationOrder.status = status;
          existingPendingTransportationOrder.location = location;
        }
      })
      .addCase(updateTransportationOrder.rejected, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.meta.arg.id]: action.payload as Rejected,
        };
      })
      .addCase(resetUpdateTransportationOrderLifecycle, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.payload.id]: ComponentLifecycle.INITIAL,
        };
      })
      .addCase(deleteTransportationOrder.pending, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.meta.arg]: ComponentLifecycle.FETCHING_DATA,
        };
      })
      .addCase(deleteTransportationOrder.fulfilled, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.payload]: ComponentLifecycle.IDLE,
        };
        const id = action.payload;
        const existingTransportationOrder = state.entities
          .find((transportationOrder) => transportationOrder.id === id);
        if (existingTransportationOrder) {
          state.entities = state.entities
            .filter((transportationOrder) => transportationOrder.id !== id);
        }
        const existingAvailableTransportationOrder = state.availableEntities
          .find((transportationOrder) => transportationOrder.id === id);
        if (existingAvailableTransportationOrder) {
          state.availableEntities = state.availableEntities
            .filter((transportationOrder) => transportationOrder.id !== id);
        }
        const existingPendingTransportationOrder = state.pendingEntities
          .find((transportationOrder) => transportationOrder.id === id);
        if (existingPendingTransportationOrder) {
          state.pendingEntities = state.pendingEntities
            .filter((transportationOrder) => transportationOrder.id !== id);
        }
      })
      .addCase(deleteTransportationOrder.rejected, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.meta.arg]: action.payload as Rejected,
        };
      })
      .addCase(deliveryNoteTransportationOrder.pending, (state) => {
        state.deliveryNoteLifecycle = ComponentLifecycle.FETCHING_DATA;
      })
      .addCase(deliveryNoteTransportationOrder.fulfilled, (state) => {
        state.deliveryNoteLifecycle = ComponentLifecycle.IDLE;
      })
      .addCase(deliveryNoteTransportationOrder.rejected, (state, action) => {
        state.deliveryNoteLifecycle = action.payload as Rejected;
      });
  },
});

export const selectTransportationOrders = (
  state: RootState,
) => state.transportationOrders.entities;
export const selectAvailableTransportationOrders = (
  state: RootState,
) => state.transportationOrders.availableEntities;
export const selectPendingTransportationOrders = (
  state: RootState,
) => state.transportationOrders.pendingEntities;
export const selectAvailableTransportationOrderLocations = (
  state: RootState,
) => state.transportationOrders.availableLocations;
export const selectAvailableTransportationOrderStatus = (
  state: RootState,
) => state.transportationOrders.availableStatus;
export const selectAvailableTransportationOrderTypes = (
  state: RootState,
) => state.transportationOrders.availableTypes;
export const selectAvailableTransportationOrderTransportTypes = (
  state: RootState,
) => state.transportationOrders.availableTransportTypes;
export const selectListTransportationOrderLifecycle = (
  state: RootState,
) => state.transportationOrders.listLifecycle;
export const selectListAvailableTransportationOrderLifecycle = (
  state: RootState,
) => state.transportationOrders.listAvailableLifecycle;
export const selectListPendingTransportationOrderLifecycle = (
  state: RootState,
) => state.transportationOrders.listPendingLifecycle;
export const selectListAvailableTransportationOrderLocationsLifecycle = (
  state: RootState,
) => state.transportationOrders.listLocationsLifecycle;
export const selectListAvailableTransportationOrderStatusLifecycle = (
  state: RootState,
) => state.transportationOrders.listStatusLifecycle;
export const selectListAvailableTransportationOrderTypesLifecycle = (
  state: RootState,
) => state.transportationOrders.listTypesLifecycle;
export const selectListAvailableTransportationOrderTransportTypesLifecycle = (
  state: RootState,
) => state.transportationOrders.listTransportTypesLifecycle;
export const selectCreateTransportationOrderLifecycle = (
  state: RootState,
) => state.transportationOrders.createLifecycle;
export const selectUpdateTransportationOrderLifecycle = (
  state: RootState,
) => state.transportationOrders.updateLifecycle;
export const selectDeleteTransportationOrderLifecycle = (
  state: RootState,
) => state.transportationOrders.deleteLifecycle;
export const selectDeliveryNoteTransportationOrderLifecycle = (
  state: RootState,
) => state.transportationOrders.deliveryNoteLifecycle;

export const selectTransportationOrdersAmount = (state: RootState) => state.transportationOrders
  .entities.length;

export const selectTransportationOrderById = (orderId: string) => (state: RootState) => state
  .transportationOrders.entities
  .find((transportationOrder: TransportationOrder) => transportationOrder.id === orderId);

export default transportationOrdersSlice.reducer;
