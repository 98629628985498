import axios from '../../app/axiosInterceptor';
import { Vehicle, CreateVehicle } from './vehicleTypes';

export function listVehiclesAPI() {
  return axios({
    method: 'get',
    url: 'list-vehicles',
  });
}

export function listAvailableVehiclesAPI(
  startDate: string,
  endDate: string,
  currentRouteId: string | null,
) {
  return axios({
    method: 'get',
    url: 'list-available-vehicles',
    params: {
      start_date: startDate,
      end_date: endDate,
      ...(currentRouteId ? { route_id: currentRouteId } : {}),
    },
  });
}

export function createVehicleAPI(vehicle: CreateVehicle) {
  return axios({
    method: 'post',
    url: 'create-vehicle',
    data: vehicle,
  });
}

export function updateVehicleAPI(vehicle: Vehicle) {
  return axios({
    method: 'post',
    url: 'update-vehicle',
    data: vehicle,
  });
}

export function deleteVehicleAPI(vehicleId: string) {
  return axios({
    method: 'post',
    url: 'delete-vehicle',
    data: { id: vehicleId },
  });
}
