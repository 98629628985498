/* eslint-disable camelcase,jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';

import { Button } from 'primereact/button';
import {
  // eslint-disable-next-line max-len
  selectVehicleById, updateVehicle, createVehicle, selectCreateVehicleLifecycle, resetCreateVehicleLifecycle, selectUpdateVehicleLifecycle, resetUpdateVehicleLifecycle,
} from './vehiclesSlice';
import { useAppSelector } from '../../app/hooks';
import { ComponentLifecycle, Rejected } from '../../constants';

export function AddEditVehicle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams() as any;
  const vehicle = useAppSelector(selectVehicleById(id));

  const createLifecycle = useAppSelector(selectCreateVehicleLifecycle);
  useEffect(() => {
    if (createLifecycle === ComponentLifecycle.IDLE) {
      dispatch(resetCreateVehicleLifecycle());
      navigate('/vehicles');
    }
  }, [createLifecycle]);

  const updateLifecycle = useAppSelector(selectUpdateVehicleLifecycle);
  useEffect(() => {
    if ((updateLifecycle[id]) === ComponentLifecycle.IDLE) {
      dispatch(resetUpdateVehicleLifecycle({ id }));
      navigate('/vehicles');
    }
  }, [updateLifecycle]);

  const [model, setModel] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [capacity, setCapacity] = useState(0);

  useEffect(() => {
    if (vehicle) {
      setModel(vehicle.model);
      setLicensePlate(vehicle.license_plate);
      setCapacity(vehicle.capacity);
    }
  }, [vehicle]);

  const handleModel = (e:any) => setModel(e.target.value);
  const handleLicensePlate = (e:any) => setLicensePlate(e.target.value);
  const handleCapacity = (e:any) => setCapacity(e.target.value);

  const handleClick = () => {
    if (id && id !== 'add') {
      dispatch(
        updateVehicle({
          id,
          model,
          license_plate: licensePlate,
          capacity,
        }),
      );
    } else if (id === 'add') {
      dispatch(
        createVehicle({
          model,
          license_plate: licensePlate,
          capacity,
        }),
      );
    }
  };

  const isVisible = true;
  const setVisible = () => {
    dispatch(resetUpdateVehicleLifecycle({ id }));
    navigate('/vehicles');
  };

  const getInvalidFieldClass = (field: string) => {
    const { form_errors } = id === 'add' ? (createLifecycle as Rejected || {}) : (updateLifecycle[id] as Rejected || {});
    return form_errors && form_errors[field] ? 'w-12 p-invalid' : 'w-12';
  };

  const renderInvalidFieldMessage = (field:string) => {
    const { form_errors } = id === 'add' ? (createLifecycle as Rejected || {}) : (updateLifecycle[id] as Rejected || {});

    return form_errors && form_errors[field] ? (
      form_errors[field].map((error) => (
        <div key={field} className="invalid-form-input-message">
          {error.message}
        </div>
      ))
    ) : null;
  };

  return (

    <div>
      <Sidebar
        data-testid="edit-vehicle-sidebar"
        visible={isVisible}
        position="right"
        className="p-sidebar-md"
        onHide={() => setVisible()}
        style={{ backgroundColor: '#F2F4F6' }}
        blockScroll
      >
        <h2 data-testid="vehicle-sidebar-title" className="font-bold mt-4 mb-0">
          {id === 'add' ? 'Crear vehículo' : 'Editar vehículo'}
        </h2>

        <div className="mt-6">
          <span className="p-float-label">
            <InputText
              data-testid="vehicle-form-model-input"
              className={getInvalidFieldClass('name')}
              type="text"
              id="modelInput"
              onChange={handleModel}
              value={model}
            />
            <label htmlFor="modelInput">
              Modelo
            </label>
          </span>
          {renderInvalidFieldMessage('model')}
        </div>
        <div className="mt-6">
          <span className="p-float-label">
            <InputText
              data-testid="vehicle-form-license-plate-input"
              className={getInvalidFieldClass('license_plate')}
              type="text"
              id="licensePlateInput"
              onChange={handleLicensePlate}
              value={licensePlate}
            />
            <label htmlFor="licensePlateInput">Matrícula</label>
          </span>
          {renderInvalidFieldMessage('license_plate')}
        </div>
        <div className="mt-6">
          <span className="p-float-label">
            <InputText
              data-testid="vehicle-form-capacity-input"
              className={getInvalidFieldClass('capacity')}
              type="number"
              id="capacityInput"
              onChange={handleCapacity}
              value={capacity}
            />
            <label htmlFor="capacityInput">Capacidad</label>
          </span>
          {renderInvalidFieldMessage('capacity')}
        </div>

        <div className="w-12 flex justify-content-end mt-6">
          <Button
            data-testid="vehicle-form-submit-button"
            label={id === 'add' ? 'Crear' : 'Editar'}
            icon="pi pi-upload"
            onClick={handleClick}
            loading={id === 'add' ? createLifecycle === ComponentLifecycle.FETCHING_DATA : updateLifecycle[id] === ComponentLifecycle.FETCHING_DATA}

          />
        </div>
      </Sidebar>
    </div>
  );
}
