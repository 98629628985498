/* eslint-disable react/no-children-prop */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { DataTable } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Card } from 'primereact/card';
import { FilterMatchMode } from 'primereact/api';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown';
import {
  selectTransportationOrders,
  deleteTransportationOrder,
  listTransportationOrders,
  selectDeleteTransportationOrderLifecycle,
  selectDeliveryNoteTransportationOrderLifecycle,
  deliveryNoteTransportationOrder,
  listAvailableTransportationOrders,
  selectAvailableTransportationOrders,
  selectListAvailableTransportationOrderLifecycle,
  selectPendingTransportationOrders,
  listPendingTransportationOrders,
  selectAvailableTransportationOrderTypes,
  listAvailableTransportationOrderTypes,
  selectListTransportationOrderLifecycle,
  selectListPendingTransportationOrderLifecycle,
} from './transportationOrdersSlice';
import { useAppSelector } from '../../app/hooks';
import { ComponentLifecycle } from '../../constants';
import { TransportationOrder, TransportationOrderType } from './transportationOrderTypes';

export function TransportationOrdersTable() {
  const [deleteEntityId, setDeleteEntityId] = useState('');
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const dispatch = useDispatch();

  const transportationOrders = useAppSelector(selectTransportationOrders);
  const pendingTransportationOrders = useAppSelector(selectPendingTransportationOrders);
  const availableTransportationOrders = useAppSelector(selectAvailableTransportationOrders);
  const listLifecycle = useAppSelector(selectListTransportationOrderLifecycle);
  const listPendingLifecycle = useAppSelector(selectListPendingTransportationOrderLifecycle);
  const listAvailableLifecycle = useAppSelector(selectListAvailableTransportationOrderLifecycle);
  const deleteLifecycle = useAppSelector(selectDeleteTransportationOrderLifecycle);
  const deliveryNoteLifecycle = useAppSelector(selectDeliveryNoteTransportationOrderLifecycle);
  const availableTransportationOrdersTypes = useAppSelector(
    selectAvailableTransportationOrderTypes,
  );

  const handleDelete = (id: string) => {
    dispatch(deleteTransportationOrder(id));
  };

  const [orders, setTransportOrders] = useState(availableTransportationOrders);
  const [showOption, setShowOption] = useState(1);
  const handleShowOption = (e:any) => {
    const option = e.value;
    setShowOption(option);
    if (option === 1) {
      setTransportOrders(availableTransportationOrders);
    } else if (option === 2) {
      setTransportOrders(pendingTransportationOrders);
    } else if (option === 3) {
      setTransportOrders(transportationOrders);
    }
  };

  const [transportationOrderTypes,
    setOrderTypes] = useState<TransportationOrderType[]>([]);

  useEffect(() => {
    dispatch(listAvailableTransportationOrders());
    dispatch(listPendingTransportationOrders());
    dispatch(listTransportationOrders());
    dispatch(listAvailableTransportationOrderTypes());
  }, [dispatch]);

  useEffect(() => {
    if (showOption === 1 && availableTransportationOrders) {
      setTransportOrders(availableTransportationOrders);
    }
  }, [availableTransportationOrders]);

  useEffect(() => {
    if (showOption === 2 && pendingTransportationOrders) {
      setTransportOrders(pendingTransportationOrders);
    }
  }, [pendingTransportationOrders]);

  useEffect(() => {
    if (showOption === 3 && transportationOrders) {
      setTransportOrders(transportationOrders);
    }
  }, [transportationOrders]);

  useEffect(() => {
    if (availableTransportationOrdersTypes) {
      setOrderTypes(availableTransportationOrdersTypes);
    }
  }, [availableTransportationOrdersTypes]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'type.id': { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e:any) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const showSelectItems = [
    { label: 'Disponibles', value: 1 },
    { label: 'Pendientes', value: 2 },
    { label: 'Todas', value: 3 },
  ];

  const tableHeader = () => (
    <div className="flex w-12 justify-content-between">
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar matrícula o teléfono..." />
        </span>
        <span className="ml-3">
          <Dropdown value={showOption} options={showSelectItems} onChange={handleShowOption} />
        </span>
      </div>
      <Link to="/transportation-orders/add">
        <Button icon="pi pi-plus" className="p-button-rounded p-button-raised p-button-success" />
      </Link>
    </div>
  );

  const rejectFunc = () => {
    // @ts-ignore
    toast.current.show({
      severity: 'warn', summary: 'Rejected', detail: 'Operación rechazada', life: 3000,
    });
  };

  const acceptFunc = (transportationOrderId: string) => {
    handleDelete(transportationOrderId);
  };

  const actionsColumnTemplate = (transportationOrder: TransportationOrder) => (
    <div className="flex">
      <Link to={`/transportation-orders/${transportationOrder.id}`}>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-raised" />
      </Link>
      {(transportationOrder.type.id !== 5) && (
        <Button
          data-testid="delivery-note-transportation-order-button"
          onClick={() => {
            dispatch(deliveryNoteTransportationOrder(transportationOrder.id));
          }}
          icon="pi pi-file-pdf"
          className="p-button-rounded p-button-raised p-button-secondary ml-2"
          loading={deliveryNoteLifecycle === ComponentLifecycle.FETCHING_DATA}
        />
      )}
      <Button
        data-testid={`delete-transportation-order-button-${transportationOrder.id}`}
        onClick={() => {
          setDeleteEntityId(transportationOrder.id);
          setVisible(true);
        }}
        icon="pi pi-trash"
        className="p-button-rounded p-button-raised p-button-danger ml-2"
        loading={deleteLifecycle[transportationOrder.id] === ComponentLifecycle.FETCHING_DATA}
      />
    </div>
  );

  const paymentPartColumnTemplate = (order: TransportationOrder) => (
    order.payment_part ? <i className="pi pi-thumbs-up color-green" /> : <i className="pi pi-thumbs-down color-red" />
  );

  const deliveryNoteColumnTemplate = (order: TransportationOrder) => (
    order.delivery_note ? <i className="pi pi-thumbs-up color-green" /> : <i className="pi pi-thumbs-down color-red" />
  );

  function statusColor(id: number) {
    switch (id) {
      case 1:
        return '#de028d';
      case 2:
        return '#de7402';
      case 3:
        return '#2e9e02';
      default:
        return 'black';
    }
  }

  const statusColumnTemplate = (order: TransportationOrder) => (
    <span
      style={{ color: statusColor(order.status.id) }}
    >
      {order.status.description}
    </span>
  );

  const typeFilterTemplate = (options: ColumnFilterElementTemplateOptions) => (
    <Dropdown
      value={options.value}
      options={transportationOrderTypes}
      onChange={(type: DropdownChangeParams) => options.filterApplyCallback(type.value)}
      optionValue="id"
      optionLabel="description"
      className="p-column-filter"
      showClear
    />
  );

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog
        acceptLabel="Sí"
        rejectLabel="No"
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que quiere continuar?"
        header="Confirmación"
        icon="pi pi-exclamation-triangle"
        accept={() => acceptFunc(deleteEntityId)}
        reject={rejectFunc}
      />
      <Card className="card--padding-0">
        <DataTable
          data-testid="transportationOrders-table"
          responsiveLayout="scroll"
          value={orders}
          sortField="created"
          sortOrder={1}
          loading={(showOption === 1 && listAvailableLifecycle === ComponentLifecycle.FETCHING_DATA)
              || (showOption === 2 && listPendingLifecycle === ComponentLifecycle.FETCHING_DATA)
              || (showOption === 3 && listLifecycle === ComponentLifecycle.FETCHING_DATA)}
          header={tableHeader}
          filters={filters}
          filterDisplay="row"
          globalFilterFields={['license_plate', 'phone']}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column field="license_plate" filterField="license_plate" header="Matrícula" sortable />
          <Column field="model" header="Modelo" sortable />
          <Column
            field="type.description"
            filterField="type.id"
            header="Tipo"
            sortable
            filter
            showFilterMenu={false}
            filterElement={typeFilterTemplate}
          />
          <Column field="transport_type.description" header="Tipo transporte" sortable />
          <Column field="address.address_line" header="Dirección" sortable />
          <Column field="address.postal_code" header="Código postal" sortable />
          <Column field="address.locality" header="Localidad" sortable />
          <Column field="address.region" header="Región" sortable />
          <Column field="address.country" header="País" sortable />
          <Column field="name" header="Nombre" sortable />
          <Column field="phone" filterField="phone" header="Teléfono" sortable />
          <Column field="notes" header="Anotaciones" sortable />
          <Column field="transport_notes" header="Anotaciones transporte" sortable />
          <Column field="priority" header="Prioridad" sortable />
          <Column field="delivery_note" header="Albarán" body={deliveryNoteColumnTemplate} sortable />
          <Column field="contact_date" header="Fecha contacto" sortable />
          <Column field="availability" header="Disponibilidad" sortable />
          <Column field="location.description" header="Ubicación" sortable />
          <Column field="transport_date" header="Fecha transporte" sortable />
          <Column field="payment_part" header="Parte pago" body={paymentPartColumnTemplate} sortable />
          <Column field="status" header="Estado" body={statusColumnTemplate} sortable />
          <Column field="created" header="Fecha creación" sortable />
          <Column field="id" header="Acciones" body={actionsColumnTemplate} />
        </DataTable>
      </Card>
    </div>
  );
}
