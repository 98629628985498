/* eslint-disable camelcase,jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';

import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import {
  selectCarrierById,
  updateCarrier,
  createCarrier,
  selectCreateCarrierLifecycle,
  selectUpdateCarrierLifecycle,
  resetUpdateCarrierLifecycle,
  resetCreateCarrierLifecycle, selectCarrierCategories, listCarrierCategories,
} from './carriersSlice';

import { useAppSelector } from '../../app/hooks';
import { ComponentLifecycle, Rejected, availableDays } from '../../constants';
import { CarrierCategory } from './carrierTypes';
import { Vehicle } from '../vehicles/vehicleTypes';
import { listVehicles, selectVehicles } from '../vehicles/vehiclesSlice';

export function AddEditCarrier() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams() as any;
  const carrier = useAppSelector(selectCarrierById(id));
  const carrierCategories = useAppSelector(selectCarrierCategories);
  const vehicles = useAppSelector(selectVehicles);

  const createLifecycle = useAppSelector(selectCreateCarrierLifecycle);
  useEffect(() => {
    if (createLifecycle === ComponentLifecycle.IDLE) {
      dispatch(resetCreateCarrierLifecycle());
      navigate('/carriers');
    }
  }, [createLifecycle]);

  const updateLifecycle = useAppSelector(selectUpdateCarrierLifecycle);
  useEffect(() => {
    if ((updateLifecycle[id]) === ComponentLifecycle.IDLE) {
      dispatch(resetUpdateCarrierLifecycle({ id }));
      navigate('/carriers');
    }
  }, [updateLifecycle]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [category, setCategory] = useState<CarrierCategory|null>(null);
  const [zone, setZone] = useState('');
  const [insurance, setInsurance] = useState(false);
  const [availability, setAvailability] = useState<string[]>([]);
  const [overnight, setOvernight] = useState(false);
  const [maxKm, setMaxKm] = useState(0);
  const [vehicle, setVehicle] = useState<Vehicle|null>(null);

  useEffect(() => {
    dispatch(listCarrierCategories());
    dispatch(listVehicles());
  }, [dispatch]);

  useEffect(() => {
    if (carrier) {
      setName(carrier.name);
      setEmail(carrier.email);
      setPhone(carrier.phone);
      setCategory(carrier.category);
      setZone(carrier.zone);
      setInsurance(carrier.insurance);
      setAvailability(carrier.availability.split(','));
      setOvernight(carrier.overnight);
      setMaxKm(carrier.max_km);
      setVehicle(carrier.vehicle);
    }
  }, [carrier]);

  const handleName = (e:any) => setName(e.target.value);
  const handleEmail = (e:any) => setEmail(e.target.value);
  const handlePhone = (e:any) => setPhone(e.target.value);
  const handleCategory = (e:any) => setCategory(e.target.value);
  const handleZone = (e:any) => setZone(e.target.value);
  const handleInsurance = (e:any) => setInsurance(e.target.checked);
  const handleAvailability = (e:any) => setAvailability(e.target.value);
  const handleOvernight = (e:any) => setOvernight(e.target.checked);
  const handleMaxKm = (e:any) => setMaxKm(e.target.value);
  const handleVehicle = (e:any) => setVehicle(e.target.value);

  const handleClick = () => {
    if (id && id !== 'add') {
      dispatch(
        updateCarrier({
          id,
          name,
          email,
          phone,
          category_id: category?.id,
          zone,
          insurance,
          availability: availability.toString(),
          overnight,
          max_km: maxKm,
          vehicle_id: vehicle?.id,
        }),
      );
    } else if (id === 'add') {
      dispatch(
        createCarrier({
          name,
          email,
          phone,
          category_id: category?.id,
          zone,
          insurance,
          availability: availability.toString(),
          overnight,
          max_km: maxKm,
          vehicle_id: vehicle?.id,
        }),
      );
    }
  };

  const isVisible = true;
  const setVisible = () => {
    dispatch(resetUpdateCarrierLifecycle({ id }));
    navigate('/carriers');
  };

  const getInvalidFieldClass = (field: string) => {
    const { form_errors } = id === 'add' ? (createLifecycle as Rejected || {}) : (updateLifecycle[id] as Rejected || {});
    return form_errors && form_errors[field] ? 'w-12 p-invalid' : 'w-12';
  };

  const renderInvalidFieldMessage = (field:string) => {
    const { form_errors } = id === 'add' ? (createLifecycle as Rejected || {}) : (updateLifecycle[id] as Rejected || {});

    return form_errors && form_errors[field] ? (
      form_errors[field].map((error) => (
        <div key={field} className="invalid-form-input-message">
          {error.message}
        </div>
      ))
    ) : null;
  };

  return (

    <div>
      <Sidebar
        data-testid="edit-carrier-sidebar"
        visible={isVisible}
        position="right"
        className="p-sidebar-md"
        onHide={() => setVisible()}
        style={{ backgroundColor: '#F2F4F6' }}
        blockScroll
      >
        <h2 data-testid="carrier-sidebar-title" className="font-bold mt-4 mb-0">
          {id === 'add' ? 'Crear transportista' : 'Editar transportista'}
        </h2>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="edit-carrier-name-input"
                className={getInvalidFieldClass('name')}
                type="text"
                id="nameInput"
                onChange={handleName}
                value={name}

              />
              <label htmlFor="nameInput">
                Name
              </label>
            </span>
            {renderInvalidFieldMessage('name')}
          </div>
          <div className="mt-6 w-6 ml-2">
            <span className="p-float-label">
              <InputText
                data-testid="edit-carrier-email-input"
                className={getInvalidFieldClass('email')}
                type="email"
                id="emailInput"
                onChange={handleEmail}
                value={email}
              />
              <label htmlFor="emailInput">Email</label>
            </span>
            {renderInvalidFieldMessage('email')}
          </div>
        </div>
        <div className="flex">
          <div className="w-6 mt-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="edit-carrier-phone-input"
                className={getInvalidFieldClass('phone')}
                type="tel"
                id="phoneInput"
                onChange={handlePhone}
                value={phone}
              />
              <label htmlFor="phoneInput">Teléfono</label>
            </span>
            {renderInvalidFieldMessage('phone')}
          </div>
          <div className="w-6 mt-6 ml-2">
            <span className="p-float-label">
              <Dropdown
                data-testid="edit-carrier-category-input"
                className={getInvalidFieldClass('category')}
                value={category}
                options={carrierCategories}
                onChange={handleCategory}
                optionLabel="description"
                id="categoryInput"
              />
              <label htmlFor="categoryInput">Categoria</label>
            </span>
            {renderInvalidFieldMessage('category')}
          </div>
        </div>
        {(category?.id === 1 || category?.id === 2) && (
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <Dropdown
                className={getInvalidFieldClass('vehicle')}
                value={vehicle}
                options={vehicles}
                onChange={handleVehicle}
                optionLabel="license_plate"
                filter
                showClear
                filterBy="license_plate"
                id="vehicleInput"
              />
              <label htmlFor="vehicleInput">
                Vehículo
              </label>
            </span>
            {renderInvalidFieldMessage('vehicle')}
          </div>
        </div>
        ) }
        <div className="flex">
          <div className="w-6 mt-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="edit-carrier-zone-input"
                className={getInvalidFieldClass('zone')}
                type="text"
                id="zoneInput"
                onChange={handleZone}
                value={zone}
              />
              <label htmlFor="zoneInput">Zona</label>
            </span>
            {renderInvalidFieldMessage('zone')}
          </div>
          <div className="w-6 mt-6 ml-2">
            <span className="p-float-label">
              <InputText
                data-testid="edit-carrier-maxkm-input"
                className={getInvalidFieldClass('max_km')}
                type="number"
                id="maxKmInput"
                onChange={handleMaxKm}
                value={maxKm}
              />
              <label htmlFor="maxKmInput">Max km</label>
            </span>
            {renderInvalidFieldMessage('max_km')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <Checkbox
                data-testid="edit-carrier-insurance-input"
                onChange={handleInsurance}
                checked={insurance}
                id="insuranceInput"
              />
              <label htmlFor="insuranceInput" className="ml-4">Seguro</label>
            </span>
          </div>
          <div className="mt-6 w-6 ml-2">
            <span className="p-float-label">
              <Checkbox
                data-testid="edit-carrier-overnight-input"
                onChange={handleOvernight}
                checked={overnight}
                id="overnightInput"
              />
              <label htmlFor="overnightInput" className="ml-4">Pernocta</label>
            </span>
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-12">
            <span className="p-float-label">
              <MultiSelect
                data-testid="edit-carrier-availability-input"
                className="custom-multiselect"
                id="availabilityInput"
                value={availability}
                options={availableDays}
                onChange={handleAvailability}
                maxSelectedLabels={3}
              />
              <label htmlFor="availabilityInput">Disponibilidad</label>
            </span>
            {renderInvalidFieldMessage('availability')}
          </div>
        </div>

        <div className="w-12 flex justify-content-end mt-6">
          <Button
            data-testid="carrier-form-submit-button"
            label={id === 'add' ? 'Crear' : 'Editar'}
            icon="pi pi-upload"
            onClick={handleClick}
            loading={id === 'add' ? createLifecycle === ComponentLifecycle.FETCHING_DATA : updateLifecycle[id] === ComponentLifecycle.FETCHING_DATA}
          />
        </div>
      </Sidebar>
    </div>
  );
}
