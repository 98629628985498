/* eslint-disable camelcase */
import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Carrier, CarrierPost, CarrierState, CreateCarrier,
} from './carrierTypes';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';
import {
  createCarrierAPI,
  deleteCarrierAPI,
  listCarrierCategoriesAPI,
  listCarriersAPI,
  listAvailableCarriersAPI,
  updateCarrierAPI,
} from './carrierAPI';
import {
  ComponentLifecycle, FormErrors, ResponseErrorData, Rejected,
} from '../../constants';

export const initialState: CarrierState = {
  entities: [],
  entitiesCategories: [],
  entitiesAvailable: [],
  listAvailableLifecycle: ComponentLifecycle.INITIAL,
  listLifecycle: ComponentLifecycle.INITIAL,
  listCategoriesLifecycle: ComponentLifecycle.INITIAL,
  createLifecycle: ComponentLifecycle.INITIAL,
  updateLifecycle: {},
  deleteLifecycle: {},
};

export const listCarriers = createAsyncThunk(
  'list-carriers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listCarriersAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listAvailableCarriers = createAsyncThunk(
  'list-available-carriers',
  async (
    dates: { startDate: string; endDate: string; currentRouteId: string | null },
    { rejectWithValue },
  ) => {
    try {
      const response = await listAvailableCarriersAPI(
        dates.startDate,
        dates.endDate,
        dates.currentRouteId,
      );
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listCarrierCategories = createAsyncThunk(
  'list-carrier-categories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listCarrierCategoriesAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const createCarrier = createAsyncThunk(
  'create-carrier',
  async (carrier: CreateCarrier, { rejectWithValue }) => {
    try {
      const response = await createCarrierAPI(carrier);
      return response.data;
    } catch (responseError: any) {
      const { name, message, form_errors } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
        form_errors: form_errors ? JSON.parse(form_errors) as FormErrors : {},
      } as Rejected);
    }
  },
);

export const updateCarrier = createAsyncThunk(
  'update-carrier',
  async (carrier: CarrierPost, { rejectWithValue }) => {
    try {
      const response = await updateCarrierAPI(carrier);
      return response.data;
    } catch (responseError: any) {
      const { name, message, form_errors } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
        form_errors: form_errors ? JSON.parse(form_errors) as FormErrors : {},
      } as Rejected);
    }
  },
);

export const deleteCarrier = createAsyncThunk(
  'delete-carrier',
  async (carrierId: string, { rejectWithValue }) => {
    try {
      const response = await deleteCarrierAPI(carrierId);

      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const resetCreateCarrierLifecycle = createAction('Reset Create Carrier Lifecycle');
export const resetUpdateCarrierLifecycle = createAction<{id: string}>('Reset Update Carrier Lifecycle');

const carriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCarriers.pending, (state) => {
        state.listLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.entities = [];
      })
      .addCase(listCarriers.fulfilled, (state, action) => {
        state.listLifecycle = ComponentLifecycle.IDLE;
        state.entities = [...action.payload];
      })
      .addCase(listCarriers.rejected, (state, action) => {
        state.listLifecycle = action.payload as Rejected;
      })
      .addCase(listAvailableCarriers.pending, (state) => {
        state.listAvailableLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.entitiesAvailable = [];
      })
      .addCase(listAvailableCarriers.fulfilled, (state, action) => {
        state.listAvailableLifecycle = ComponentLifecycle.IDLE;
        state.entitiesAvailable = [...action.payload];
      })
      .addCase(listAvailableCarriers.rejected, (state, action) => {
        state.listAvailableLifecycle = action.payload as Rejected;
      })
      .addCase(listCarrierCategories.pending, (state) => {
        state.listCategoriesLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.entitiesCategories = [];
      })
      .addCase(listCarrierCategories.fulfilled, (state, action) => {
        state.listCategoriesLifecycle = ComponentLifecycle.IDLE;
        state.entitiesCategories = [...action.payload];
      })
      .addCase(listCarrierCategories.rejected, (state, action) => {
        state.listCategoriesLifecycle = action.payload as Rejected;
      })
      .addCase(createCarrier.pending, (state) => {
        state.createLifecycle = ComponentLifecycle.FETCHING_DATA;
      })
      .addCase(createCarrier.fulfilled, (state, action) => {
        state.createLifecycle = ComponentLifecycle.IDLE;
        state.entities.push(action.payload);
      })
      .addCase(createCarrier.rejected, (state, action) => {
        state.createLifecycle = action.payload as Rejected;
      })
      .addCase(resetCreateCarrierLifecycle, (state) => {
        state.createLifecycle = ComponentLifecycle.INITIAL;
      })
      .addCase(updateCarrier.pending, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.meta.arg.id]: ComponentLifecycle.FETCHING_DATA,
        };
      })
      .addCase(updateCarrier.fulfilled, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.payload.id]: ComponentLifecycle.IDLE,
        };
        const {
          id,
          name,
          email,
          phone,
          category,
          zone,
          insurance,
          availability,
          overnight,
          max_km,
          vehicle,
        } = action.payload;
        const existingCarrier = state.entities.find((carrier) => carrier.id === id);
        if (existingCarrier) {
          existingCarrier.name = name;
          existingCarrier.email = email;
          existingCarrier.phone = phone;
          existingCarrier.category = category;
          existingCarrier.zone = zone;
          existingCarrier.insurance = insurance;
          existingCarrier.availability = availability;
          existingCarrier.overnight = overnight;
          existingCarrier.max_km = max_km;
          existingCarrier.vehicle = vehicle;
        }
      })
      .addCase(updateCarrier.rejected, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.meta.arg.id]: action.payload as Rejected,
        };
      })
      .addCase(resetUpdateCarrierLifecycle, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.payload.id]: ComponentLifecycle.INITIAL,
        };
      })
      .addCase(deleteCarrier.pending, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.meta.arg]: ComponentLifecycle.FETCHING_DATA,
        };
      })
      .addCase(deleteCarrier.fulfilled, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.payload]: ComponentLifecycle.IDLE,
        };
        const id = action.payload;
        const existingCarrier = state.entities.find((carrier) => carrier.id === id);
        if (existingCarrier) {
          state.entities = state.entities.filter((carrier) => carrier.id !== id);
        }
      })
      .addCase(deleteCarrier.rejected, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.meta.arg]: action.payload as Rejected,
        };
      });
  },
});

export const selectCarriers = (state: RootState) => state.carriers.entities;
export const selectAvailableCarriers = (state: RootState) => state.carriers.entitiesAvailable;
export const selectCarrierCategories = (state: RootState) => state.carriers.entitiesCategories;
export const selectListCarrierLifecycle = (state: RootState) => state.carriers.listLifecycle;
export const selectListCarrierCategoryLifecycle = (state: RootState) => state.carriers
  .listCategoriesLifecycle;
export const selectCreateCarrierLifecycle = (state: RootState) => state.carriers.createLifecycle;
export const selectUpdateCarrierLifecycle = (state: RootState) => state.carriers.updateLifecycle;
export const selectDeleteCarrierLifecycle = (state: RootState) => state.carriers.deleteLifecycle;

export const selectCarriersAmount = (state: RootState) => state.carriers.entities.length;

export const selectCarrierById = (carrierId: string) => (state: RootState) => state.carriers
  .entities.find((carrier: Carrier) => carrier.id === carrierId);

export default carriersSlice.reducer;
