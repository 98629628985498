import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import carriersReducer from '../features/carriers/carriersSlice';
// eslint-disable-next-line import/no-cycle
import vehiclesReducer from '../features/vehicles/vehiclesSlice';
// eslint-disable-next-line import/no-cycle
import TransportationOrdersReducer from '../features/transportation-orders/transportationOrdersSlice';
// eslint-disable-next-line import/no-cycle
import RoutesReducer from '../features/routes/routesSlice';

export const store = configureStore({
  reducer: {
    carriers: carriersReducer,
    vehicles: vehiclesReducer,
    transportationOrders: TransportationOrdersReducer,
    routes: RoutesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
    >;
