/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import { Vehicle, VehicleState, CreateVehicle } from './vehicleTypes';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';
import {
  createVehicleAPI, deleteVehicleAPI, listVehiclesAPI, updateVehicleAPI, listAvailableVehiclesAPI,
} from './vehicleAPI';
import {
  ComponentLifecycle, Rejected, ResponseErrorData, FormErrors,
} from '../../constants';

export const initialState: VehicleState = {
  entities: [],
  entitiesAvailable: [],
  listAvailableLifecycle: ComponentLifecycle.INITIAL,
  listLifecycle: ComponentLifecycle.INITIAL,
  createLifecycle: ComponentLifecycle.INITIAL,
  updateLifecycle: {},
  deleteLifecycle: {},
};

export const listVehicles = createAsyncThunk(
  'list-vehicles',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listVehiclesAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listAvailableVehicles = createAsyncThunk(
  'list-available-vehicles',
  async (
    dates: { startDate: string; endDate: string; currentRouteId: string | null },
    { rejectWithValue },
  ) => {
    try {
      const response = await listAvailableVehiclesAPI(
        dates.startDate,
        dates.endDate,
        dates.currentRouteId,
      );
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const createVehicle = createAsyncThunk(
  'create-vehicle',
  async (vehicle: CreateVehicle, { rejectWithValue }) => {
    try {
      const response = await createVehicleAPI(vehicle);
      return response.data;
    } catch (responseError: any) {
      const { name, message, form_errors } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
        form_errors: form_errors ? JSON.parse(form_errors) as FormErrors : {},
      } as Rejected);
    }
  },
);

export const updateVehicle = createAsyncThunk(
  'update-vehicle',
  async (vehicle: Vehicle, { rejectWithValue }) => {
    try {
      const response = await updateVehicleAPI(vehicle);
      return response.data;
    } catch (responseError: any) {
      const { name, message, form_errors } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
        form_errors: form_errors ? JSON.parse(form_errors) as FormErrors : {},
      } as Rejected);
    }
  },
);

export const deleteVehicle = createAsyncThunk(
  'delete-vehicle',
  async (vehicleId: string, { rejectWithValue }) => {
    try {
      const response = await deleteVehicleAPI(vehicleId);
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const resetCreateVehicleLifecycle = createAction('Reset Create Vehicle Lifecycle');
export const resetUpdateVehicleLifecycle = createAction<{id: string}>('Reset Update Vehicle Lifecycle');

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(listVehicles.pending, (state) => {
        state.listLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.entities = [];
      })
      .addCase(listVehicles.fulfilled, (state, action) => {
        state.listLifecycle = ComponentLifecycle.IDLE;
        state.entities = [...action.payload];
      })
      .addCase(listVehicles.rejected, (state, action) => {
        state.listLifecycle = action.payload as Rejected;
      })
      .addCase(createVehicle.pending, (state) => {
        state.createLifecycle = ComponentLifecycle.FETCHING_DATA;
      })
      .addCase(listAvailableVehicles.pending, (state) => {
        state.listAvailableLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.entitiesAvailable = [];
      })
      .addCase(listAvailableVehicles.fulfilled, (state, action) => {
        state.listAvailableLifecycle = ComponentLifecycle.IDLE;
        state.entitiesAvailable = [...action.payload];
      })
      .addCase(listAvailableVehicles.rejected, (state, action) => {
        state.listAvailableLifecycle = action.payload as Rejected;
      })
      .addCase(createVehicle.fulfilled, (state, action) => {
        state.createLifecycle = ComponentLifecycle.IDLE;
        state.entities.push(action.payload);
      })
      .addCase(createVehicle.rejected, (state, action) => {
        state.createLifecycle = action.payload as Rejected;
      })
      .addCase(resetCreateVehicleLifecycle, (state) => {
        state.createLifecycle = ComponentLifecycle.INITIAL;
      })
      .addCase(updateVehicle.pending, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.meta.arg.id]: ComponentLifecycle.FETCHING_DATA,
        };
      })
      .addCase(updateVehicle.fulfilled, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.payload.id]: ComponentLifecycle.IDLE,
        };
        const {
          id, model, license_plate, capacity,
        } = action.payload;
        const existingVehicle = state.entities.find((vehicle) => vehicle.id === id);
        if (existingVehicle) {
          existingVehicle.model = model;
          existingVehicle.license_plate = license_plate;
          existingVehicle.capacity = capacity;
        }
      })
      .addCase(updateVehicle.rejected, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.meta.arg.id]: action.payload as Rejected,
        };
      })
      .addCase(resetUpdateVehicleLifecycle, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.payload.id]: ComponentLifecycle.INITIAL,
        };
      })
      .addCase(deleteVehicle.pending, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.meta.arg]: ComponentLifecycle.FETCHING_DATA,
        };
      })
      .addCase(deleteVehicle.fulfilled, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.payload]: ComponentLifecycle.IDLE,
        };
        const id = action.payload;
        const existingVehicle = state.entities.find((vehicle) => vehicle.id === id);
        if (existingVehicle) {
          state.entities = state.entities.filter((vehicle) => vehicle.id !== id);
        }
      })
      .addCase(deleteVehicle.rejected, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.meta.arg]: action.payload as Rejected,
        };
      });
  },
});

export const selectVehicles = (state: RootState) => state.vehicles.entities;
export const selectAvailableVehicles = (state: RootState) => state.vehicles.entitiesAvailable;
export const selectListVehicleLifecycle = (state: RootState) => state.vehicles.listLifecycle;
export const selectCreateVehicleLifecycle = (state: RootState) => state.vehicles.createLifecycle;
export const selectUpdateVehicleLifecycle = (state: RootState) => state.vehicles.updateLifecycle;
export const selectDeleteVehicleLifecycle = (state: RootState) => state.vehicles.deleteLifecycle;
export const selectVehiclesAmount = (state: RootState) => state.vehicles.entities.length;

export const selectVehicleById = (vehicleId: string) => (state: RootState) => state.vehicles
  .entities.find((vehicle: Vehicle) => vehicle.id === vehicleId);

export default vehiclesSlice.reducer;
