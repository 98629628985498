/* eslint-disable react/no-children-prop */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { FilterMatchMode } from 'primereact/api';

import { InputText } from 'primereact/inputtext';
import {
  deleteVehicle,
  listVehicles, selectVehicles, selectListVehicleLifecycle,
  selectDeleteVehicleLifecycle,
} from './vehiclesSlice';
import { useAppSelector } from '../../app/hooks';
import { ComponentLifecycle } from '../../constants';
import { Vehicle } from './vehicleTypes';

export function VehiclesTable() {
  const [deleteEntityId, setDeleteEntityId] = useState('');
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);

  const dispatch = useDispatch();

  const vehicles = useAppSelector(selectVehicles);
  const listLifecycle = useAppSelector(selectListVehicleLifecycle);
  const deleteLifecycle = useAppSelector(selectDeleteVehicleLifecycle);

  const handleDelete = (id: string) => {
    dispatch(deleteVehicle(id));
  };

  useEffect(() => {
    dispatch(listVehicles());
  }, [dispatch]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e:any) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const tableHeader = () => (
    <div className="flex w-12 justify-content-between">
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar por matrícula..." />
        </span>
      </div>
      <Link to="/vehicles/add">
        <Button icon="pi pi-plus" className="p-button-rounded p-button-raised p-button-success" />
      </Link>
    </div>
  );

  const rejectFunc = () => {
    // @ts-ignore
    toast.current.show({
      severity: 'warn', summary: 'Rejected', detail: 'Operación rechazada', life: 3000,
    });
  };

  const acceptFunc = (vehicleId: string) => {
    handleDelete(vehicleId);
  };

  const actionsColumnTemplate = (vehicle: Vehicle) => (
    <div className="flex">
      <Link to={`/vehicles/${vehicle.id}`}>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-raised" />
      </Link>
      <Button
        data-testid={`delete-vehicle-button-${vehicle.id}`}
        onClick={() => {
          setDeleteEntityId(vehicle.id);
          setVisible(true);
        }}
        icon="pi pi-trash"
        className="p-button-rounded p-button-raised p-button-danger ml-2"
        loading={deleteLifecycle[vehicle.id] === ComponentLifecycle.FETCHING_DATA}
      />
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog
        acceptLabel="Sí"
        rejectLabel="No"
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que quiere continuar?"
        header="Confirmación"
        icon="pi pi-exclamation-triangle"
        accept={() => acceptFunc(deleteEntityId)}
        reject={rejectFunc}
      />
      <Card className="card--padding-0">
        <DataTable
          data-testid="vehicles-table"
          responsiveLayout="scroll"
          value={vehicles}
          sortField="model"
          sortOrder={1}
          loading={listLifecycle === ComponentLifecycle.FETCHING_DATA}
          header={tableHeader}
          filters={filters}
          globalFilterFields={['license_plate']}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column field="model" header="Modelo" sortable />
          <Column field="license_plate" filterField="license_plate" header="Matrícula" sortable />
          <Column field="capacity" header="Capacidad" sortable />
          <Column field="id" header="Acciones" body={actionsColumnTemplate} />
        </DataTable>
      </Card>
    </div>
  );
}
