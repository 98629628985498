import axios from '../../app/axiosInterceptor';
import { CarrierPost, CreateCarrier } from './carrierTypes';

export function listCarriersAPI() {
  return axios({
    method: 'get',
    url: 'list-carriers',
  });
}

export function listAvailableCarriersAPI(
  startDate: string,
  endDate: string,
  currentRouteId: string | null,
) {
  return axios({
    method: 'get',
    url: 'list-available-carriers',
    params: {
      start_date: startDate,
      end_date: endDate,
      ...(currentRouteId ? { route_id: currentRouteId } : {}),
    },
  });
}

export function listCarrierCategoriesAPI() {
  return axios({
    method: 'get',
    url: 'list-carrier-categories',
  });
}

export function createCarrierAPI(carrier: CreateCarrier) {
  return axios({
    method: 'post',
    url: 'create-carrier',
    data: carrier,
  });
}

export function updateCarrierAPI(carrier: CarrierPost) {
  return axios({
    method: 'post',
    url: 'update-carrier',
    data: carrier,
  });
}

export function deleteCarrierAPI(carrierId: string) {
  return axios({
    method: 'post',
    url: 'delete-carrier',
    data: { id: carrierId },
  });
}
