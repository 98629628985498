import axios from '../../app/axiosInterceptor';
import { TransportationOrderPost, CreateTransportationOrder } from './transportationOrderTypes';

export function listTransportationOrdersAPI() {
  return axios({
    method: 'get',
    url: 'list-transportation-orders',
  });
}

export function listAvailableTransportationOrdersAPI() {
  return axios({
    method: 'get',
    url: 'list-available-transportation-orders',
  });
}

export function listPendingTransportationOrdersAPI() {
  return axios({
    method: 'get',
    url: 'list-pending-transportation-orders',
  });
}

export function createTransportationOrderAPI(transportationOrder: CreateTransportationOrder) {
  return axios({
    method: 'post',
    url: 'create-transportation-order',
    data: transportationOrder,
  });
}

export function updateTransportationOrderAPI(transportationOrder: TransportationOrderPost) {
  return axios({
    method: 'post',
    url: 'update-transportation-order',
    data: transportationOrder,
  });
}

export function deleteTransportationOrderAPI(transportationOrderId: string) {
  return axios({
    method: 'post',
    url: 'delete-transportation-order',
    data: { id: transportationOrderId },
  });
}

export function listTransportationOrderLocationsAPI() {
  return axios({
    method: 'get',
    url: 'list-transportation-order-locations',
  });
}

export function listTransportationOrderStatusAPI() {
  return axios({
    method: 'get',
    url: 'list-transportation-order-status',
  });
}

export function listTransportationOrderTypesAPI() {
  return axios({
    method: 'get',
    url: 'list-transportation-order-types',
  });
}

export function listTransportationOrderTransportTypesAPI() {
  return axios({
    method: 'get',
    url: 'list-transportation-order-transport-types',
  });
}

export function deliveryNoteTransportationOrderAPI(transportationOrderId: string) {
  return axios({
    method: 'post',
    responseType: 'blob',
    url: 'delivery-note-transportation-order',
    data: { id: transportationOrderId },
  });
}
