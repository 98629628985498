/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import {
  Route, RouteState, CreateRoute, RoutePost,
} from './routeTypes';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';
import {
  createRouteAPI,
  deleteRouteAPI,
  getRoutesByLicensePlateAPI,
  listRouteOrdersAPI,
  listRoutesAPI,
  updateRouteAPI,
} from './routesAPI';
import {
  ComponentLifecycle, Rejected, ResponseErrorData, FormErrors,
} from '../../constants';

export const initialState: RouteState = {
  entities: [],
  routeOrders: [],
  searchedRoutes: [],
  listLifecycle: ComponentLifecycle.INITIAL,
  createLifecycle: ComponentLifecycle.INITIAL,
  updateLifecycle: {},
  deleteLifecycle: {},
};

export const listRoutes = createAsyncThunk(
  'list-routes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await listRoutesAPI();
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const listRouteOrders = createAsyncThunk(
  'list-orders-from-routes',
  async (routeId: string, { rejectWithValue }) => {
    try {
      const response = await listRouteOrdersAPI(routeId);
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const getRoutesByLicensePlate = createAsyncThunk(
  'get-routes-by-license-plate',
  async (licensePlate: string, { rejectWithValue }) => {
    try {
      const response = await getRoutesByLicensePlateAPI(licensePlate);
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const createRoute = createAsyncThunk(
  'create-route',
  async (route: CreateRoute, { rejectWithValue }) => {
    try {
      const response = await createRouteAPI(route);
      return response.data;
    } catch (responseError: any) {
      const { name, message, form_errors } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
        form_errors: form_errors ? JSON.parse(form_errors) as FormErrors : {},
      } as Rejected);
    }
  },
);

export const updateRoute = createAsyncThunk(
  'update-route',
  async (route: RoutePost, { rejectWithValue }) => {
    try {
      const response = await updateRouteAPI(route);
      return response.data;
    } catch (responseError: any) {
      const { name, message, form_errors } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
        form_errors: form_errors ? JSON.parse(form_errors) as FormErrors : {},
      } as Rejected);
    }
  },
);

export const deleteRoute = createAsyncThunk(
  'delete-route',
  async (routeId: string, { rejectWithValue }) => {
    try {
      const response = await deleteRouteAPI(routeId);
      return response.data;
    } catch (responseError: any) {
      const { name, message } = responseError.response.data as ResponseErrorData;
      return rejectWithValue({
        name,
        message,
      } as Rejected);
    }
  },
);

export const resetCreateRouteLifecycle = createAction('Reset Create Route Lifecycle');
export const resetUpdateRouteLifecycle = createAction<{id: string}>('Reset Update Route Lifecycle');

const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(listRoutes.pending, (state) => {
        state.listLifecycle = ComponentLifecycle.FETCHING_DATA;
        state.entities = [];
      })
      .addCase(listRoutes.fulfilled, (state, action) => {
        state.listLifecycle = ComponentLifecycle.IDLE;
        state.entities = [...action.payload];
      })
      .addCase(listRoutes.rejected, (state, action) => {
        state.listLifecycle = action.payload as Rejected;
      })
      .addCase(listRouteOrders.pending, (state) => {
        state.routeOrders = [];
      })
      .addCase(listRouteOrders.fulfilled, (state, action) => {
        state.routeOrders = [...action.payload];
      })
      .addCase(getRoutesByLicensePlate.pending, (state) => {
        state.searchedRoutes = [];
      })
      .addCase(getRoutesByLicensePlate.fulfilled, (state, action) => {
        state.searchedRoutes = [...action.payload];
      })
      .addCase(createRoute.pending, (state) => {
        state.createLifecycle = ComponentLifecycle.FETCHING_DATA;
      })
      .addCase(createRoute.fulfilled, (state, action) => {
        state.createLifecycle = ComponentLifecycle.IDLE;
        state.entities.push(action.payload);
      })
      .addCase(createRoute.rejected, (state, action) => {
        state.createLifecycle = action.payload as Rejected;
      })
      .addCase(resetCreateRouteLifecycle, (state) => {
        state.createLifecycle = ComponentLifecycle.INITIAL;
      })
      .addCase(updateRoute.pending, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.meta.arg.id]: ComponentLifecycle.FETCHING_DATA,
        };
      })
      .addCase(updateRoute.fulfilled, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.payload.id]: ComponentLifecycle.IDLE,
        };
        const {
          id,
          carrier,
          vehicle,
          km,
          route_date_init,
          route_date_end,
          route_address_init,
          route_address_end,
          closed,
        } = action.payload;
        const existingRoute = state
          .entities.find((route) => route.id === id);
        if (existingRoute) {
          existingRoute.carrier = carrier;
          existingRoute.vehicle = vehicle;
          existingRoute.km = km;
          existingRoute.route_date_init = route_date_init;
          existingRoute.route_date_end = route_date_end;
          existingRoute.route_address_init = route_address_init;
          existingRoute.route_address_end = route_address_end;
          existingRoute.closed = closed;
        }
      })
      .addCase(updateRoute.rejected, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.meta.arg.id]: action.payload as Rejected,
        };
      })
      .addCase(resetUpdateRouteLifecycle, (state, action) => {
        state.updateLifecycle = {
          ...state.updateLifecycle,
          [action.payload.id]: ComponentLifecycle.INITIAL,
        };
      })
      .addCase(deleteRoute.pending, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.meta.arg]: ComponentLifecycle.FETCHING_DATA,
        };
      })
      .addCase(deleteRoute.fulfilled, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.payload]: ComponentLifecycle.IDLE,
        };
        const id = action.payload;
        const existingRoute = state.entities
          .find((route) => route.id === id);
        if (existingRoute) {
          state.entities = state.entities
            .filter((route) => route.id !== id);
        }
      })
      .addCase(deleteRoute.rejected, (state, action) => {
        state.deleteLifecycle = {
          ...state.deleteLifecycle,
          [action.meta.arg]: action.payload as Rejected,
        };
      });
  },
});

export const selectRoutes = (state: RootState) => state.routes.entities;
export const selectRouteOrders = (state: RootState) => state.routes.routeOrders;
export const selectSearchedRoutes = (state: RootState) => state.routes.searchedRoutes;
export const selectListRouteLifecycle = (state: RootState) => state.routes.listLifecycle;
export const selectCreateRouteLifecycle = (state: RootState) => state.routes.createLifecycle;
export const selectUpdateRouteLifecycle = (state: RootState) => state.routes.updateLifecycle;
export const selectDeleteRouteLifecycle = (state: RootState) => state.routes.deleteLifecycle;

export const selectRoutesAmount = (state: RootState) => state.routes
  .entities.length;

export const selectRouteById = (routeId: string) => (state: RootState) => state
  .routes.entities
  .find((route: Route) => route.id === routeId);

export default routesSlice.reducer;
