import './App.css';
import {
  Route,
  BrowserRouter,
  Routes,
  Outlet,
} from 'react-router-dom';
import React, { FC } from 'react';
import { CarriersTable } from './features/carriers/CarriersTable';
import { Header } from './panel/Header';
import { Welcome } from './panel/Welcome';
import { VehiclesTable } from './features/vehicles/VehiclesTable';
import { RoutesTable } from './features/routes/RoutesTable';
import { TransportationOrdersTable } from './features/transportation-orders/TransportationOrdersTable';
import { AddEditCarrier } from './features/carriers/AddEditCarrier';
import { AddEditVehicle } from './features/vehicles/AddEditVehicle';
import { AddEditRoute } from './features/routes/AddEditRoute';
import { AddToken } from './features/tokens/AddToken';
import { RedirectLogInPage, RemoveToken } from './features/tokens/RemoveToken';
import { Message } from './shared/Message';
import { AddEditTransportationOrder } from './features/transportation-orders/AddEditTransportationOrder';

const PrivateOutlet: FC = function privateOutlet({ children }) {
  const token = localStorage.getItem('token');

  return (process.env.NODE_ENV === 'production' && (token === null || token === undefined || token === '')) ? (
    <RedirectLogInPage /> // Go back to log-in
  ) : (
    <>
      {children}
      <Outlet />
    </>
  );
};

export function RoutesWrapper() {
  return (
    <>
      <Routes>
        <Route element={<PrivateOutlet />}>
          <Route path="/" element={<Welcome />} />
          <Route path="carriers" element={<CarriersTable />}>
            <Route path=":id" element={<AddEditCarrier />} />
          </Route>
          <Route path="vehicles" element={<VehiclesTable />}>
            <Route path=":id" element={<AddEditVehicle />} />
          </Route>
          <Route path="transportation-orders" element={<TransportationOrdersTable />}>
            <Route path=":id" element={<AddEditTransportationOrder />} />
          </Route>
          <Route path="routes" element={<RoutesTable />}>
            <Route path=":id" element={<AddEditRoute />} />
          </Route>
        </Route>
      </Routes>
      <Routes>
        <Route element={<PrivateOutlet />}>
          <Route path="transportation-orders/:id" element={<AddEditTransportationOrder />} />
          <Route path="carriers/:id" element={<AddEditCarrier />} />
          <Route path="vehicles/:id" element={<AddEditVehicle />} />
          <Route path="routes/:id" element={<AddEditRoute />} />
        </Route>
      </Routes>
      <Routes>
        <Route path="token" element={<AddToken />} />
        <Route path="logout" element={<RemoveToken />} />
      </Routes>
    </>
  );
}

export default function App() {
  return (
    <div>
      <Header />
      <div className="content-wrapper">
        <BrowserRouter>
          <RoutesWrapper />
        </BrowserRouter>
      </div>
      <Message />
    </div>
  );
}
