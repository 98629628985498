/* eslint-disable no-unused-vars */
export const DateFormat = 'DD-MM-YYYY';

export const backendDEV = 'https://services.piston.one/transport-api/transportation-requester/';
export const backendPROD = 'https://operations.mundimoto.com/transport-api/transportation-requester/';

export interface ResponseErrorData {
  name: string;
  message: string;
  form_errors?: string;
}

export interface ResponseError {
  response: {
    data: ResponseErrorData;
  }
}

export interface FormErrors {
  [key: string]: {
    message: string;
    code: string;
  }[];
}

export interface Rejected extends Omit<ResponseErrorData, 'form_errors'> {
  form_errors?: FormErrors;
}

export type ComponentLifecycleValues = 'INITIAL' | 'FETCHING_DATA' | 'IDLE' | Rejected;

// eslint-disable-next-line no-shadow
export enum ComponentLifecycle {
  INITIAL = 'INITIAL',
  FETCHING_DATA = 'FETCHING_DATA',
  IDLE = 'IDLE'
}

export const availableDays = [
  'Absoluta',
  'Lunes mañana',
  'Lunes tarde',
  'Martes mañana',
  'Martes tarde',
  'Miércoles mañana',
  'Miércoles tarde',
  'Jueves mañana',
  'Jueves tarde',
  'Viernes mañana',
  'Viernes tarde',
  'Sábado mañana',
  'Sábado tarde',
];
