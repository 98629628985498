import React, { useEffect, useRef } from 'react';
import { Toast, ToastSeverityType } from 'primereact/toast';
import { useAppSelector } from '../app/hooks';
import {
  selectListCarrierLifecycle,
  selectUpdateCarrierLifecycle,
  selectCreateCarrierLifecycle,
  selectDeleteCarrierLifecycle,
} from '../features/carriers/carriersSlice';
import { ComponentLifecycle, Rejected } from '../constants';
import {
  selectListRouteLifecycle,
  selectCreateRouteLifecycle,
  selectUpdateRouteLifecycle,
  selectDeleteRouteLifecycle,
} from '../features/routes/routesSlice';
import {
  selectListTransportationOrderLifecycle,
  selectListAvailableTransportationOrderLifecycle,
  selectListAvailableTransportationOrderLocationsLifecycle,
  selectCreateTransportationOrderLifecycle,
  selectUpdateTransportationOrderLifecycle,
  selectDeleteTransportationOrderLifecycle,
  selectListAvailableTransportationOrderStatusLifecycle,
} from '../features/transportation-orders/transportationOrdersSlice';
import {
  selectListVehicleLifecycle,
  selectCreateVehicleLifecycle,
  selectUpdateVehicleLifecycle,
  selectDeleteVehicleLifecycle,
} from '../features/vehicles/vehiclesSlice';

export function Message() {
  const toast: any = useRef(null);

  const listCarrierLifecycle = useAppSelector(selectListCarrierLifecycle);
  const createCarrierLifecycle = useAppSelector(selectCreateCarrierLifecycle);
  const updateCarrierLifecycle = useAppSelector(selectUpdateCarrierLifecycle);
  const deleteCarrierLifecycle = useAppSelector(selectDeleteCarrierLifecycle);

  const listVehicleLifecycle = useAppSelector(selectListVehicleLifecycle);
  const createVehicleLifecycle = useAppSelector(selectCreateVehicleLifecycle);
  const updateVehicleLifecycle = useAppSelector(selectUpdateVehicleLifecycle);
  const deleteVehicleLifecycle = useAppSelector(selectDeleteVehicleLifecycle);

  const listAvailableTransportationOrderLocationsLifecycle = useAppSelector(
    selectListAvailableTransportationOrderLocationsLifecycle,
  );

  const listAvailableTransportationOrderStatusLifecycle = useAppSelector(
    selectListAvailableTransportationOrderStatusLifecycle,
  );

  const listTransportationOrderLifecycle = useAppSelector(selectListTransportationOrderLifecycle);
  const listAvailableTransportationOrderLifecycle = useAppSelector(
    selectListAvailableTransportationOrderLifecycle,
  );
  const createTransportationOrderLifecycle = useAppSelector(
    selectCreateTransportationOrderLifecycle,
  );
  const updateTransportationOrderLifecycle = useAppSelector(
    selectUpdateTransportationOrderLifecycle,
  );
  const deleteTransportationOrderLifecycle = useAppSelector(
    selectDeleteTransportationOrderLifecycle,
  );

  const listRouteLifecycle = useAppSelector(selectListRouteLifecycle);
  const createRouteLifecycle = useAppSelector(selectCreateRouteLifecycle);
  const updateRouteLifecycle = useAppSelector(selectUpdateRouteLifecycle);
  const deleteRouteLifecycle = useAppSelector(selectDeleteRouteLifecycle);

  const displayMessage = (severity: ToastSeverityType, summary: string, detail: string) => {
    toast.current.show({
      severity,
      summary,
      detail,
      life: 7000,
    });
  };

  // CARRIERS EFFECTS
  useEffect(() => {
    const listError = listCarrierLifecycle as Rejected;

    if (listError?.name) {
      displayMessage('error', listError.name, listError.message);
    }
  }, [listCarrierLifecycle]);

  useEffect(() => {
    const createError = createCarrierLifecycle as Rejected;
    if (createError?.name) {
      displayMessage('error', createError.name, createError.message);
    }
    if (createCarrierLifecycle === ComponentLifecycle.IDLE) {
      displayMessage('success', 'Hecho', 'El transportista se ha creado correctamente');
    }
  }, [createCarrierLifecycle]);

  useEffect(() => {
    Object.keys(updateCarrierLifecycle).forEach((id) => {
      const error = updateCarrierLifecycle[id] as Rejected;
      if (error?.name) {
        displayMessage('error', error.name, error.message);
      }
      if (updateCarrierLifecycle[id] === ComponentLifecycle.IDLE) {
        displayMessage('success', 'Hecho', 'El transportista se ha editado correctamente');
      }
    });
  }, [updateCarrierLifecycle]);

  useEffect(() => {
    Object.keys(deleteCarrierLifecycle).forEach((id) => {
      const error = deleteCarrierLifecycle[id] as Rejected;

      if (error?.name) {
        displayMessage('error', error.name, error.message);
      }
      if (deleteCarrierLifecycle[id] === ComponentLifecycle.IDLE) {
        displayMessage('success', 'Hecho', 'El transportista se ha eliminado correctamente');
      }
    });
  }, [deleteCarrierLifecycle]);

  // VEHICLES EFFECTS
  useEffect(() => {
    const listError = listVehicleLifecycle as Rejected;

    if (listError?.name) {
      displayMessage('error', listError.name, listError.message);
    }
  }, [listVehicleLifecycle]);

  useEffect(() => {
    const createError = createVehicleLifecycle as Rejected;
    if (createVehicleLifecycle === ComponentLifecycle.IDLE) {
      displayMessage('success', 'Hecho', 'El vehículo se ha creado correctamente');
    }
    if (createError?.name) {
      displayMessage('error', createError.name, createError.message);
    }
  }, [createVehicleLifecycle]);

  useEffect(() => {
    Object.keys(updateVehicleLifecycle).forEach((id) => {
      const error = updateVehicleLifecycle[id] as Rejected;

      if (error?.name) {
        displayMessage('error', error.name, error.message);
      }
      if (updateVehicleLifecycle[id] === ComponentLifecycle.IDLE) {
        displayMessage('success', 'Hecho', 'El vehículo se ha editado correctamente');
      }
    });
  }, [updateVehicleLifecycle]);

  useEffect(() => {
    Object.keys(deleteVehicleLifecycle).forEach((id) => {
      const error = deleteVehicleLifecycle[id] as Rejected;

      if (error?.name) {
        displayMessage('error', error.name, error.message);
      }
      if (deleteVehicleLifecycle[id] === ComponentLifecycle.IDLE) {
        displayMessage('success', 'Hecho', 'El vehículo se ha eliminado correctamente');
      }
    });
  }, [deleteVehicleLifecycle]);

  // TRANSPORTATION ORDERS EFFECTS
  useEffect(() => {
    const listError = listTransportationOrderLifecycle as Rejected;

    if (listError?.name) {
      displayMessage('error', listError.name, listError.message);
    }
  }, [listTransportationOrderLifecycle]);

  useEffect(() => {
    const listError = listAvailableTransportationOrderLifecycle as Rejected;

    if (listError?.name) {
      displayMessage('error', listError.name, listError.message);
    }
  }, [listAvailableTransportationOrderLifecycle]);

  useEffect(() => {
    const listError = listAvailableTransportationOrderLocationsLifecycle as Rejected;

    if (listError?.name) {
      displayMessage('error', listError.name, listError.message);
    }
  }, [listAvailableTransportationOrderLocationsLifecycle]);

  useEffect(() => {
    const listError = listAvailableTransportationOrderStatusLifecycle as Rejected;

    if (listError?.name) {
      displayMessage('error', listError.name, listError.message);
    }
  }, [listAvailableTransportationOrderStatusLifecycle]);

  useEffect(() => {
    const createError = createTransportationOrderLifecycle as Rejected;
    if (createTransportationOrderLifecycle === ComponentLifecycle.IDLE) {
      displayMessage('success', 'Hecho', 'La orden de transporte se ha creado correctamente');
    }
    if (createError?.name) {
      displayMessage('error', createError.name, createError.message);
    }
  }, [createTransportationOrderLifecycle]);

  useEffect(() => {
    Object.keys(updateTransportationOrderLifecycle).forEach((id) => {
      const error = updateTransportationOrderLifecycle[id] as Rejected;

      if (error?.name) {
        displayMessage('error', error.name, error.message);
      }
      if (updateTransportationOrderLifecycle[id] === ComponentLifecycle.IDLE) {
        displayMessage('success', 'Hecho', 'La orden de transporte se ha editado correctamente');
      }
    });
  }, [updateTransportationOrderLifecycle]);

  useEffect(() => {
    Object.keys(deleteTransportationOrderLifecycle).forEach((id) => {
      const error = deleteTransportationOrderLifecycle[id] as Rejected;

      if (error?.name) {
        displayMessage('error', error.name, error.message);
      }
      if (deleteTransportationOrderLifecycle[id] === ComponentLifecycle.IDLE) {
        displayMessage('success', 'Hecho', 'La orden de transporte se ha eliminado correctamente');
      }
    });
  }, [deleteTransportationOrderLifecycle]);

  // ROUTES EFFECTS
  useEffect(() => {
    const listError = listRouteLifecycle as Rejected;

    if (listError?.name) {
      displayMessage('error', listError.name, listError.message);
    }
  }, [listRouteLifecycle]);

  useEffect(() => {
    const createError = createRouteLifecycle as Rejected;
    if (createError?.name) {
      displayMessage('error', createError.name, createError.message);
    }
    if (createRouteLifecycle === ComponentLifecycle.IDLE) {
      displayMessage('success', 'Hecho', 'La ruta se ha creado correctamente');
    }
  }, [createRouteLifecycle]);

  useEffect(() => {
    Object.keys(updateRouteLifecycle).forEach((id) => {
      const error = updateRouteLifecycle[id] as Rejected;
      if (error?.name) {
        displayMessage('error', error.name, error.message);
      }
      if (updateRouteLifecycle[id] === ComponentLifecycle.IDLE) {
        displayMessage('success', 'Hecho', 'La ruta se ha editado correctamente');
      }
    });
  }, [updateRouteLifecycle]);

  useEffect(() => {
    Object.keys(deleteRouteLifecycle).forEach((id) => {
      const error = deleteRouteLifecycle[id] as Rejected;

      if (error?.name) {
        displayMessage('error', error.name, error.message);
      }
      if (deleteRouteLifecycle[id] === ComponentLifecycle.IDLE) {
        displayMessage('success', 'Hecho', 'La ruta se ha eliminado correctamente');
      }
    });
  }, [deleteRouteLifecycle]);
  return (
    <div>
      <Toast ref={toast} />
    </div>
  );
}
