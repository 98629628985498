import React, { useRef } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { LogOutComponent } from '../features/tokens/LogOut';

export function Header() {
  const menu = useRef<Menu|null>(null);

  const items = [
    {
      label: 'Peticiones de transporte',
      icon: 'pi pi-fw pi-send',
      url: '/transportation-orders',
    },
    {
      label: 'Rutas',
      icon: 'pi pi-fw pi-directions',
      url: '/routes',
    },
    {
      label: 'Transportistas',
      icon: 'pi pi-fw pi-users',
      url: '/carriers',
      expanded: false,
    },
    {
      label: 'Vehículos',
      icon: 'pi pi-fw pi-car',
      url: '/vehicles',
    },
  ];

  // @ts-ignore
  const leftContents = (
    <>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <Button label="Menu" icon="pi pi-bars" onClick={(event) => menu.current?.toggle(event)} aria-controls="popup_menu" aria-haspopup />
      <h3 className="ml-4">App de transporte</h3>
    </>
  );

  const rightContents = (
    <LogOutComponent />
  );

  return (
    <div className="header-wrapper">
      <Toolbar style={{ borderRadius: 'unset', padding: '0.5rem 1rem' }} left={leftContents} right={rightContents} />
    </div>
  );
}
