import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './styles/utilities/text.scss';
import './styles/overrides/primereact.scss';
import { Provider } from 'react-redux';
import axios from 'axios';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';

import 'primereact/resources/themes/tailwind-light/theme.css'; // theme
import 'primereact/resources/primereact.min.css'; // core css
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

axios.interceptors.response.use(
  (response) => response,
  (responseError) => {
    if (!responseError.response) {
      const error = {
        response: {
          data: {
            name: responseError.name,
            message: responseError.message,
          },
        },
      };
      return Promise.reject(error);
    }
    return Promise.reject(responseError);
  },
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
