import React from 'react';
import { Dialog } from 'primereact/dialog';
import './RemoveToken.css';

export const RedirectLogInPage = function redirectPage() {
  window.location.href = 'https://mundimoto.auth.eu-west-1.amazoncognito.com/login?client_id=67equhavnnt8k7j8mu8ofj2gu1&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://transport.mundimoto.com/token';
  return null;
};

export function RemoveToken() {
  localStorage.removeItem('token');
  return (
    <Dialog header="Logged out" visible style={{ width: '30vw' }} onHide={() => { RedirectLogInPage(); }}>
      <p>Has salido de la App</p>
    </Dialog>
  );
}
