/* eslint-disable react/no-children-prop */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import {
  // eslint-disable-next-line max-len
  deleteCarrier, listCarriers, selectCarriers, selectListCarrierLifecycle, selectDeleteCarrierLifecycle,
} from './carriersSlice';
import { useAppSelector } from '../../app/hooks';
import { ComponentLifecycle } from '../../constants';
import { Carrier } from './carrierTypes';

export function CarriersTable() {
  const [deleteEntityId, setDeleteEntityId] = useState('');
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);

  const dispatch = useDispatch();

  const carriers = useAppSelector(selectCarriers);
  const listLifecycle = useAppSelector(selectListCarrierLifecycle);
  const deleteLifecycle = useAppSelector(selectDeleteCarrierLifecycle);

  const handleDelete = (id: string) => {
    dispatch(deleteCarrier(id));
  };

  useEffect(() => {
    dispatch(listCarriers());
  }, [dispatch]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e:any) => {
    const { value } = e.target;
    // eslint-disable-next-line no-underscore-dangle
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const tableHeader = () => (
    <div className="flex w-12 justify-content-between">
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar por nombre..." />
        </span>
      </div>
      <Link to="/carriers/add">
        <Button icon="pi pi-plus" className="p-button-rounded p-button-raised p-button-success" />
      </Link>
    </div>
  );

  const insuranceColumnTemplate = (carrier: Carrier) => (
    carrier.insurance ? <i className="pi pi-thumbs-up color-green" /> : <i className="pi pi-thumbs-down color-red" />
  );
  const overnightColumnTemplate = (carrier: Carrier) => (
    carrier.overnight ? <i className="pi pi-thumbs-up color-green" /> : <i className="pi pi-thumbs-down color-red" />
  );

  const rejectFunc = () => {
    // @ts-ignore
    toast.current.show({
      severity: 'warn', summary: 'Rejected', detail: 'Operación rechazada', life: 3000,
    });
  };

  const acceptFunc = (carrierId: string) => {
    handleDelete(carrierId);
  };

  const actionsColumnTemplate = (carrier: Carrier) => (
    <div className="flex">
      <Link to={`/carriers/${carrier.id}`}>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-raised" />
      </Link>
      <Button
        data-testid={`delete-carrier-button-${carrier.id}`}
        onClick={() => {
          setDeleteEntityId(carrier.id);
          setVisible(true);
        }}
        icon="pi pi-trash"
        className="p-button-rounded p-button-raised p-button-danger ml-2"
        loading={deleteLifecycle[carrier.id] === ComponentLifecycle.FETCHING_DATA}
      />
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog
        acceptLabel="Sí"
        rejectLabel="No"
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que quiere continuar?"
        header="Confirmación"
        icon="pi pi-exclamation-triangle"
        accept={() => acceptFunc(deleteEntityId)}
        reject={rejectFunc}
      />
      <Card className="card--padding-0">
        <DataTable
          data-testid="carriers-table"
          responsiveLayout="scroll"
          value={carriers}
          sortField="name"
          sortOrder={1}
          loading={listLifecycle === ComponentLifecycle.FETCHING_DATA}
          header={tableHeader}
          filters={filters}
          globalFilterFields={['name']}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column field="name" filterField="name" header="Nombre" sortable />
          <Column field="email" header="Email" sortable />
          <Column field="phone" header="Teléfono" sortable />
          <Column field="category.description" header="Categoría" sortable />
          <Column field="vehicle.license_plate" header="Vehículo" sortable />
          <Column field="zone" header="Zona" sortable />
          <Column field="insurance" header="Seguro" body={insuranceColumnTemplate} sortable />
          <Column field="availability" header="Disponibilidad" sortable />
          <Column field="overnight" header="Pernocta" body={overnightColumnTemplate} sortable />
          <Column field="max_km" header="Max km" sortable />
          <Column field="id" header="Acciones" body={actionsColumnTemplate} />
        </DataTable>
      </Card>
    </div>
  );
}
