/* eslint-disable react/no-children-prop */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { DataTable } from 'primereact/datatable';
import { Column, ColumnSortParams } from 'primereact/column';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {
  listRoutes,
  selectRoutes,
  selectListRouteLifecycle,
  deleteRoute,
  selectDeleteRouteLifecycle,
  selectSearchedRoutes,
  getRoutesByLicensePlate,
} from './routesSlice';
import { useAppSelector } from '../../app/hooks';
import { ComponentLifecycle } from '../../constants';
import { Route } from './routeTypes';

export function RoutesTable() {
  const [deleteEntityId, setDeleteEntityId] = useState('');
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);

  const dispatch = useDispatch();

  const routes = useAppSelector(selectRoutes);
  const searchedRoutes = useAppSelector(selectSearchedRoutes);
  const listLifecycle = useAppSelector(selectListRouteLifecycle);
  const deleteLifecycle = useAppSelector(selectDeleteRouteLifecycle);

  const [routesList, setRoutesList] = useState<Route[]>(routes);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const handleDelete = (id: string) => {
    dispatch(deleteRoute(id));
  };

  useEffect(() => {
    dispatch(listRoutes());
  }, [dispatch]);

  useEffect(() => {
    if (routes) {
      setRoutesList(routes);
    }
  }, [routes]);

  useEffect(() => {
    if (globalFilterValue !== '' && searchedRoutes) {
      setRoutesList(searchedRoutes);
    }
  }, [searchedRoutes]);

  const onGlobalFilterChange = (e:any) => {
    const { value } = e.target;

    if (value === '') {
      setRoutesList(routes);
    } else {
      dispatch(getRoutesByLicensePlate(value));
    }

    setGlobalFilterValue(value);
  };

  const tableHeader = () => (
    <div className="flex w-12 justify-content-between">
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar por matrícula..."
          />
        </span>
      </div>
      <Link to="/routes/add">
        <Button icon="pi pi-plus" className="p-button-rounded p-button-raised p-button-success" />
      </Link>
    </div>
  );

  const rejectFunc = () => {
    // @ts-ignore
    toast.current.show({
      severity: 'warn', summary: 'Rejected', detail: 'Operación rechazada', life: 3000,
    });
  };

  const acceptFunc = (routeId: string) => {
    handleDelete(routeId);
  };

  const closedColumnTemplate = (route: Route) => (
    route.closed ? <i className="pi pi-thumbs-up color-green" /> : <i className="pi pi-thumbs-down color-red" />
  );

  const sortDates = (date1: any, date2: any, event: ColumnSortParams, fieldName: string) => {
    let value1 = date1;
    let value2 = date2;
    let result = null;
    if (value1 == null && value2 != null) {
      result = -1;
    } else if (value1 != null && value2 == null) {
      result = 1;
    } else if (value1 == null && value2 == null) {
      result = 0;
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      if (event.field === fieldName) {
        const x = value1.split('-');
        const y = value2.split('-');
        value1 = x[2] + x[1] + x[0];
        value2 = y[2] + y[1] + y[0];
      }
      result = value1.localeCompare(value2);
    } else {
      // eslint-disable-next-line no-nested-ternary
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }
    return result;
  };

  const dataTableSortFunctionInitDate = (event: ColumnSortParams): any[] => [...routesList].sort(
    (data1: Route, data2: Route) => {
      const value1 = data1.route_date_init;
      const value2 = data2.route_date_init;
      const result = sortDates(value1, value2, event, 'route_date_init');
      if (event.order) {
        return event.order * result;
      }
      return result;
    },
  );

  const dataTableSortFunctionEndDate = (event: ColumnSortParams): any[] => [...routesList].sort(
    (data1: Route, data2: Route) => {
      const value1 = data1.route_date_end;
      const value2 = data2.route_date_end;
      const result = sortDates(value1, value2, event, 'route_date_end');
      if (event.order) {
        return event.order * result;
      }
      return result;
    },
  );

  const actionsColumnTemplate = (route: Route) => (
    <div className="flex">
      <Link to={`/routes/${route.id}`}>
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-raised" />
      </Link>
      <Button
        data-testid={`delete-route-button-${route.id}`}
        onClick={() => {
          setDeleteEntityId(route.id);
          setVisible(true);
        }}
        icon="pi pi-trash"
        className="p-button-rounded p-button-raised p-button-danger ml-2"
        loading={deleteLifecycle[route.id] === ComponentLifecycle.FETCHING_DATA}
      />
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog
        acceptLabel="Sí"
        rejectLabel="No"
        visible={visible}
        onHide={() => setVisible(false)}
        message="¿Está seguro que quiere continuar?"
        header="Confirmación"
        icon="pi pi-exclamation-triangle"
        accept={() => acceptFunc(deleteEntityId)}
        reject={rejectFunc}
      />
      <Card className="card--padding-0">
        <DataTable
          data-testid="routes-table"
          responsiveLayout="scroll"
          value={routesList}
          sortField="route_date_init"
          sortOrder={-1}
          loading={listLifecycle === ComponentLifecycle.FETCHING_DATA}
          header={tableHeader}
          globalFilterFields={['vehicle']}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
        >
          <Column field="km" header="Km" sortable />
          <Column field="carrier.name" header="Transportista" sortable />
          <Column field="vehicle.license_plate" header="Vehículo" sortable />
          <Column field="route_date_init" header="Fecha inicio" sortable sortFunction={dataTableSortFunctionInitDate} />
          <Column field="route_date_end" header="Fecha fin" sortable sortFunction={dataTableSortFunctionEndDate} />
          <Column field="route_address_init" header="Dirección inicio" sortable />
          <Column field="route_address_end" header="Dirección fin" sortable />
          <Column field="closed" header="Ruta cerrada" body={closedColumnTemplate} />
          <Column field="id" header="Acciones" body={actionsColumnTemplate} />
        </DataTable>
      </Card>
    </div>
  );
}
