import * as React from 'react';
import { Button } from 'primereact/button';

const RedirectLogOutPage = function redirectPage() {
  window.location.href = 'https://mundimoto.auth.eu-west-1.amazoncognito.com/logout?client_id=67equhavnnt8k7j8mu8ofj2gu1&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&logout_uri=https://transport.mundimoto.com/logout';
  return null;
};

export function LogOutComponent() {
  return (
    <Button
      data-testid="log-out-icon"
      icon="pi pi-sign-out"
      className="p-button-rounded p-button-secondary p-align-right"
      aria-label="Bookmark"
      iconPos="right"
      onClick={() => {
        RedirectLogOutPage();
      }}
    />
  );
}
