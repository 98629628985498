/* eslint-disable camelcase,jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';

import './AddEditRoute.css';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Card } from 'primereact/card';
import {
  selectRouteById,
  updateRoute,
  createRoute,
  selectCreateRouteLifecycle,
  resetCreateRouteLifecycle,
  selectUpdateRouteLifecycle,
  resetUpdateRouteLifecycle,
  selectRouteOrders,
  listRouteOrders,
} from './routesSlice';
import { useAppSelector } from '../../app/hooks';
import { listAvailableCarriers, selectAvailableCarriers } from '../carriers/carriersSlice';
import { listAvailableVehicles, selectAvailableVehicles } from '../vehicles/vehiclesSlice';
import { Carrier } from '../carriers/carrierTypes';
import { Vehicle } from '../vehicles/vehicleTypes';
import { TransportationOrder } from '../transportation-orders/transportationOrderTypes';
import { ComponentLifecycle, DateFormat, Rejected } from '../../constants';
import {
  listAvailableTransportationOrders,
  selectAvailableTransportationOrders,
} from '../transportation-orders/transportationOrdersSlice';

export function AddEditRoute() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const carriers = useAppSelector(selectAvailableCarriers);
  const vehicles = useAppSelector(selectAvailableVehicles);
  const availableTransportationOrders = useAppSelector(selectAvailableTransportationOrders);

  const { id } = useParams() as any;
  const route = useAppSelector(selectRouteById(id));
  const routeTransportOrders = useAppSelector(selectRouteOrders);

  const createLifecycle = useAppSelector(selectCreateRouteLifecycle);
  useEffect(() => {
    if (createLifecycle === ComponentLifecycle.IDLE) {
      dispatch(resetCreateRouteLifecycle());
      navigate('/routes');
    }
  }, [createLifecycle]);

  const updateLifecycle = useAppSelector(selectUpdateRouteLifecycle);
  useEffect(() => {
    if ((updateLifecycle[id]) === ComponentLifecycle.IDLE) {
      dispatch(resetUpdateRouteLifecycle({ id }));
      navigate('/routes');
    }
  }, [updateLifecycle]);

  const [vehicle, setVehicle] = useState<Vehicle|null>(null);
  const [carrier, setCarrier] = useState<Carrier|null>(null);
  const [transportationOrdersSource, setOrdersSource] = useState<TransportationOrder[]>([]);
  const [transportationOrdersTarget, setOrdersTarget] = useState<TransportationOrder[]>([]);
  const [km, setKm] = useState(0);
  const [routeDateInit, setDateInit] = useState<Date>(new Date());
  const [routeDateEnd, setDateEnd] = useState<Date>(new Date());
  const [routeAddressInit, setAddressInit] = useState('');
  const [routeAddressEnd, setAddressEnd] = useState('');
  const [closed, setClosed] = useState(false);

  const [globalFilterValue, setGlobalFilterValue] = useState('');

  const onGlobalFilterChange = (e:any) => {
    const { value } = e.target;
    const filTransportationOrdersSource: TransportationOrder[] = availableTransportationOrders
      .filter((order) => order.address.postal_code.includes(value));
    transportationOrdersTarget.forEach((targetOrder) => {
      filTransportationOrdersSource.forEach((sourceOrder, index) => {
        if (filTransportationOrdersSource[index].id === targetOrder.id) {
          filTransportationOrdersSource.splice(index, 1);
        }
      });
    });
    setOrdersSource(filTransportationOrdersSource);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    dispatch(listAvailableCarriers(
      {
        startDate: moment(routeDateInit).format(DateFormat),
        endDate: moment(routeDateEnd).format(DateFormat),
        currentRouteId: route ? route.id : null,
      },
    ));
    dispatch(listAvailableVehicles(
      {
        startDate: moment(routeDateInit).format(DateFormat),
        endDate: moment(routeDateEnd).format(DateFormat),
        currentRouteId: route ? route.id : null,
      },
    ));
    dispatch(listAvailableTransportationOrders());
    dispatch(listRouteOrders(id));
  }, [dispatch]);

  useEffect(() => {
    if (route) {
      setCarrier(route.carrier);
      setVehicle(route.vehicle);
      setKm(route.km);
      setDateInit(moment(route.route_date_init, DateFormat).toDate());
      setDateEnd(moment(route.route_date_end, DateFormat).toDate());
      setAddressInit(route.route_address_init);
      setAddressEnd(route.route_address_end);
      setClosed(route.closed);
    }
  }, [route]);

  useEffect(() => {
    if (availableTransportationOrders) {
      const ordersSource: TransportationOrder[] = [];
      availableTransportationOrders.forEach((order) => {
        ordersSource.push(order);
      });
      setOrdersSource(ordersSource);
    }
  }, [availableTransportationOrders]);

  useEffect(() => {
    if (routeTransportOrders) {
      const ordersTarget: TransportationOrder[] = [];
      routeTransportOrders.forEach((order) => {
        ordersTarget.push(order);
      });
      setOrdersTarget(ordersTarget);
    }
  }, [routeTransportOrders]);

  const updateVehicleAndCarrier = (dateInit: Date, dateEnd: Date) => {
    setCarrier(null);
    setVehicle(null);
    dispatch(listAvailableCarriers(
      {
        startDate: moment(dateInit).format(DateFormat),
        endDate: moment(dateEnd).format(DateFormat),
        currentRouteId: route ? route.id : null,
      },
    ));
    dispatch(listAvailableVehicles(
      {
        startDate: moment(dateInit).format(DateFormat),
        endDate: moment(dateEnd).format(DateFormat),
        currentRouteId: route ? route.id : null,
      },
    ));
  };

  const handleCarrier = (e:any) => {
    const carrierEvent = e.target.value;
    setCarrier(carrierEvent);
    if (carrierEvent.vehicle) {
      setVehicle(carrierEvent.vehicle);
    }
  };
  const handleVehicle = (e:any) => setVehicle(e.target.value);
  const handleKm = (e:any) => setKm(e.target.value);
  const handleDateInit = (e:any) => {
    setDateInit(e.target.value);
    updateVehicleAndCarrier(e.target.value, routeDateEnd);
  };
  const handleDateEnd = (e:any) => {
    setDateEnd(e.target.value);
    updateVehicleAndCarrier(routeDateInit, e.target.value);
  };
  const handleAddressInit = (e:any) => setAddressInit(e.target.value);
  const handleAddressEnd = (e:any) => setAddressEnd(e.target.value);
  const handleClosed = (e:any) => setClosed(e.target.checked);

  const handleClick = () => {
    if (id && id !== 'add') {
      dispatch(
        updateRoute({
          id,
          carrier_id: carrier?.id,
          vehicle_id: vehicle?.id,
          transportation_orders: transportationOrdersTarget.map((order) => order.id).toString(),
          km,
          route_date_init: moment(routeDateInit).format(DateFormat),
          route_date_end: moment(routeDateEnd).format(DateFormat),
          route_address_init: routeAddressInit,
          route_address_end: routeAddressEnd,
          closed,
        }),
      );
    } else if (id === 'add') {
      dispatch(
        createRoute({
          carrier_id: carrier?.id,
          vehicle_id: vehicle?.id,
          transportation_orders: transportationOrdersTarget.map((order) => order.id).toString(),
          km,
          route_date_init: moment(routeDateInit).format(DateFormat),
          route_date_end: moment(routeDateEnd).format(DateFormat),
          route_address_init: routeAddressInit,
          route_address_end: routeAddressEnd,
          closed,
        }),
      );
    }
  };

  const isVisible = true;
  const setVisible = () => {
    dispatch(resetUpdateRouteLifecycle({ id }));
    navigate('/routes');
  };

  const getInvalidFieldClass = (field: string) => {
    const { form_errors } = id === 'add' ? (createLifecycle as Rejected || {}) : (updateLifecycle[id] as Rejected || {});
    return form_errors && form_errors[field] ? 'w-12 p-invalid' : 'w-12';
  };

  const renderInvalidFieldMessage = (field:string) => {
    const { form_errors } = id === 'add' ? (createLifecycle as Rejected || {}) : (updateLifecycle[id] as Rejected || {});

    return form_errors && form_errors[field] ? (
      form_errors[field].map((error: {message: string; code: string}) => (
        <div key={field} className="invalid-form-input-message">
          {error.message}
        </div>
      ))
    ) : null;
  };

  const addToRoute = (orderId: string) => {
    const transportationOrder: TransportationOrder|undefined = transportationOrdersSource
      .find((order) => order.id === orderId);
    if (transportationOrder) {
      transportationOrdersTarget.push(transportationOrder);
      setOrdersSource(transportationOrdersSource
        .filter((order) => order.id !== orderId));
      const relatedTransportationOrders: TransportationOrder[] = transportationOrdersSource
        .filter((order) => order.id !== orderId && order.phone === transportationOrder.phone
              && order.address.postal_code === transportationOrder.address.postal_code);
      if (relatedTransportationOrders.length > 0) {
        relatedTransportationOrders.forEach(
          (relatedOrder) => transportationOrdersTarget.push(relatedOrder),
        );
        setOrdersSource(transportationOrdersSource
          .filter((order) => order.id !== orderId
              && relatedTransportationOrders.indexOf(order) === -1));
      }
    }
  };

  const deleteToRoute = (orderId: string) => {
    const transportationOrder: TransportationOrder|undefined = transportationOrdersTarget
      .find((order) => order.id === orderId);
    if (transportationOrder) {
      transportationOrdersSource.push(transportationOrder);
      setOrdersTarget(transportationOrdersTarget
        .filter((order) => order.id !== orderId));
    }
  };

  const moveUpRoute = (index: number) => {
    if (index > 0) {
      const arr: TransportationOrder[] = transportationOrdersTarget;
      const cutOut = arr.splice(index, 1)[0];
      arr.splice(index - 1, 0, cutOut);
      setOrdersTarget([...arr]);
    }
  };

  const moveDownRoute = (index: number) => {
    if (index < transportationOrdersTarget.length - 1) {
      const arr: TransportationOrder[] = transportationOrdersTarget;
      const cutOut = arr.splice(index, 1)[0];
      arr.splice(index + 1, 0, cutOut);
      setOrdersTarget([...arr]);
    }
  };

  const vehicleTemplate = (option: Vehicle) => (
    <div>
      {option.license_plate}
      {' '}
      - Capacidad(
      {option.capacity}
      )
    </div>
  );

  return (

    <div>
      <Sidebar
        data-testid="edit-route-sidebar"
        visible={isVisible}
        position="right"
        fullScreen
        onHide={() => setVisible()}
        style={{ backgroundColor: '#F2F4F6' }}
      >
        <h2 data-testid="route-sidebar-title" className="font-bold mt-4 mb-0">
          {id === 'add' ? 'Crear ruta' : 'Editar ruta'}
        </h2>

        <div className="flex">
          <div className="w-8">
            <div className="flex justify-content-between align-items-center mt-4">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Buscar..." />
              </span>
            </div>
            <div className="flex">
              <div className="mt-2 w-6 mr-4">
                <span className="p-float-label">
                  <h4>Disponibles</h4>
                  <Card className="orders-list">
                    {
                    transportationOrdersSource.map((order) => (
                      <Card className="order-card mb-2" key={order.id}>
                        <div>
                          <i
                            onClick={() => addToRoute(order.id)}
                            className="pi pi-plus cursor-pointer"
                            aria-hidden="true"
                            style={{ float: 'right' }}
                          />
                          <h5 className="mt-1 mb-1">
                            {order.license_plate}
                            {' - '}
                            {order.phone}
                            {order.payment_part && (' - ')}
                            {order.payment_part && ('PP')}
                          </h5>
                          <span>{order.address.postal_code}</span>
                        </div>
                        <div>
                          <h6 className="mt-1 mb-1">{order.availability}</h6>
                        </div>
                        <div>
                          <h6 className="mt-1 mb-1">
                            <span
                              style={{ color: order.transport_type.id === 1 ? '#fc7b03' : '#03b1fc' }}
                            >
                              {order.transport_type.description}
                            </span>
                            {' - '}
                            {order.model}
                          </h6>
                        </div>
                      </Card>
                    ))
                  }
                  </Card>
                </span>
              </div>
              <div className="mt-2 w-6 mr-4">
                <span className="p-float-label">
                  <h4>Añadidas a la ruta</h4>
                  <Card className="orders-list">
                    {
                    transportationOrdersTarget.map((order, index) => (
                      <Card className="order-card mb-2" key={order.id}>
                        <div>
                          <i
                            onClick={() => deleteToRoute(order.id)}
                            className="pi pi-minus cursor-pointer"
                            aria-hidden="true"
                            style={{ float: 'right' }}
                          />
                          <h5 className="mt-1 mb-1">
                            {order.license_plate}
                            {' - '}
                            {order.phone}
                            {order.payment_part && (' - ')}
                            {order.payment_part && ('PP')}
                          </h5>
                          <span>
                            {order.address.address_line}
                            ,
                            {' '}
                            {order.address.postal_code}
                            ,
                            {' '}
                            {order.address.locality}
                            ,
                            {' '}
                            {order.address.region}
                          </span>
                          <i
                            onClick={() => moveUpRoute(index)}
                            className="pi pi-chevron-up cursor-pointer"
                            aria-hidden="true"
                            style={{ float: 'right' }}
                          />
                        </div>
                        <div>
                          <h6 className="mt-1 mb-1">{order.availability}</h6>
                        </div>
                        <div>
                          <i
                            onClick={() => moveDownRoute(index)}
                            className="pi pi-chevron-down cursor-pointer"
                            aria-hidden="true"
                            style={{ float: 'right' }}
                          />
                          <h6 className="mt-1 mb-1">
                            <span
                              style={{ color: order.transport_type.id === 1 ? '#fc7b03' : '#03b1fc' }}
                            >
                              {order.transport_type.description}
                            </span>
                            {' - '}
                            {order.model}
                          </h6>
                        </div>
                      </Card>
                    ))
                  }
                  </Card>
                </span>
              </div>
            </div>
          </div>
          <div className="w-4">
            <div className="flex">
              <div className="mt-6 w-6 mr-2">
                <span className="p-float-label">
                  <Calendar
                    className={getInvalidFieldClass('date_init')}
                    data-testid="route-form-date-init-input"
                    id="dateInitInput"
                    onChange={handleDateInit}
                    value={routeDateInit}
                    maxDate={routeDateEnd}
                    dateFormat="dd/mm/yy"
                  />
                  <label htmlFor="dateInitInputInput">Fecha inicio</label>
                </span>
                {renderInvalidFieldMessage('date_init')}
              </div>
              <div className="mt-6 w-6 mr-2">
                <span className="p-float-label">
                  <Calendar
                    data-testid="route-form-date-end-input"
                    className={getInvalidFieldClass('date_end')}
                    id="dateEndInput"
                    onChange={handleDateEnd}
                    value={routeDateEnd}
                    minDate={routeDateInit}
                    dateFormat="dd/mm/yy"
                  />
                  <label htmlFor="dateEndInput">Fecha fin</label>
                </span>
                {renderInvalidFieldMessage('date_end')}
              </div>
            </div>
            <div className="flex">
              <div className="mt-6 w-6 mr-2">
                <span className="p-float-label">
                  <Dropdown
                    className={getInvalidFieldClass('carrier')}
                    value={carrier}
                    options={carriers}
                    onChange={handleCarrier}
                    optionLabel="name"
                    filter
                    showClear
                    filterBy="name"
                    id="carrierInput"
                  />
                  <label htmlFor="carrierInput">
                    Transportista
                  </label>
                </span>
                {renderInvalidFieldMessage('carrier')}
              </div>
              <div className="mt-6 w-6 mr-2">
                <span className="p-float-label">
                  <Dropdown
                    className={getInvalidFieldClass('vehicle')}
                    value={vehicle}
                    options={vehicles}
                    onChange={handleVehicle}
                    optionLabel="license_plate"
                    itemTemplate={vehicleTemplate}
                    filter
                    showClear
                    filterBy="license_plate"
                    id="vehicleInput"
                  />
                  <label htmlFor="vehicleInput">
                    Vehículo
                    {' '}
                    {vehicle && (
                    <div className="inline">
                      - Capacidad(
                        {vehicle.capacity}
                      )
                    </div>
                    )}
                  </label>
                </span>
                {renderInvalidFieldMessage('vehicle')}
              </div>
            </div>
            <div className="flex">
              <div className="mt-6 w-6 mr-2">
                <span className="p-float-label">
                  <InputText
                    data-testid="route-form-address-init-input"
                    className={getInvalidFieldClass('address_init')}
                    type="text"
                    id="addressInitInput"
                    onChange={handleAddressInit}
                    value={routeAddressInit}
                  />
                  <label htmlFor="addressInitInput">Dirección inicio</label>
                </span>
                {renderInvalidFieldMessage('address_init')}
              </div>
              <div className="mt-6 w-6 mr-2">
                <span className="p-float-label">
                  <InputText
                    data-testid="route-form-address-end-input"
                    className={getInvalidFieldClass('address_end')}
                    type="text"
                    id="addressEndInput"
                    onChange={handleAddressEnd}
                    value={routeAddressEnd}
                  />
                  <label htmlFor="addressEndInput">Dirección fin</label>
                </span>
                {renderInvalidFieldMessage('address_end')}
              </div>
            </div>
            <div className="flex">
              <div className="mt-6 mb-6 w-6 mr-2">
                <span className="p-float-label">
                  <InputText
                    data-testid="route-form-km-input"
                    className={getInvalidFieldClass('km')}
                    type="number"
                    id="kmInput"
                    onChange={handleKm}
                    value={km}
                  />
                  <label htmlFor="kmInput">Km</label>
                </span>
                {renderInvalidFieldMessage('km')}
              </div>
              <div className="mt-6 mb-6 w-6 mr-2">
                <span className="p-float-label">
                  <Checkbox
                    data-testid="edit-carrier-insurance-input"
                    onChange={handleClosed}
                    checked={closed}
                    id="closedInput"
                  />
                  <label htmlFor="closedInput" className="ml-4">Ruta cerrada</label>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-12 flex justify-content-end mt-6">
          <Button
            data-testid="route-form-submit-button"
            label={id === 'add' ? 'Crear' : 'Editar'}
            icon="pi pi-upload"
            onClick={handleClick}
            loading={id === 'add' ? createLifecycle === ComponentLifecycle.FETCHING_DATA : updateLifecycle[id] === ComponentLifecycle.FETCHING_DATA}
          />
        </div>
      </Sidebar>
    </div>
  );
}
