/* eslint-disable camelcase,jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import {
  TransportationOrderLocation,
  TransportationOrderStatus, TransportationOrderTransportType,
  TransportationOrderType,
} from './transportationOrderTypes';
import {
  DateFormat, availableDays, ComponentLifecycle, Rejected,
} from '../../constants';
import {
  createTransportationOrder,
  selectTransportationOrderById,
  updateTransportationOrder,
  selectCreateTransportationOrderLifecycle,
  resetCreateTransportationOrderLifecycle,
  selectUpdateTransportationOrderLifecycle,
  resetUpdateTransportationOrderLifecycle,
  listAvailableTransportationOrderLocations,
  listAvailableTransportationOrderStatus,
  selectAvailableTransportationOrderLocations,
  selectAvailableTransportationOrderStatus,
  selectAvailableTransportationOrderTypes,
  selectAvailableTransportationOrderTransportTypes,
  listAvailableTransportationOrderTypes,
  listAvailableTransportationOrderTransportTypes,
} from './transportationOrdersSlice';
import { useAppSelector } from '../../app/hooks';

export function AddEditTransportationOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [transportationOrderLocations,
    setOrderLocations] = useState<TransportationOrderLocation[]>([]);

  const [transportationOrderStatus,
    setOrderStatus] = useState<TransportationOrderStatus[]>([]);

  const [transportationOrderTypes,
    setOrderTypes] = useState<TransportationOrderType[]>([]);

  const [transportationOrderTransportTypes,
    setOrderTransportTypes] = useState<TransportationOrderTransportType[]>([]);

  const { id } = useParams() as any;
  const transportationOrder = useAppSelector(selectTransportationOrderById(id));

  const createLifecycle = useAppSelector(selectCreateTransportationOrderLifecycle);
  useEffect(() => {
    if (createLifecycle === ComponentLifecycle.IDLE) {
      dispatch(resetCreateTransportationOrderLifecycle());
      navigate('/transportation-orders');
    }
  }, [createLifecycle]);

  const updateLifecycle = useAppSelector(selectUpdateTransportationOrderLifecycle);
  useEffect(() => {
    if ((updateLifecycle[id]) === ComponentLifecycle.IDLE) {
      dispatch(resetUpdateTransportationOrderLifecycle({ id }));
      navigate('/transportation-orders');
    }
  }, [updateLifecycle]);

  useEffect(() => {
    dispatch(listAvailableTransportationOrderLocations());
    dispatch(listAvailableTransportationOrderStatus());
    dispatch(listAvailableTransportationOrderTypes());
    dispatch(listAvailableTransportationOrderTransportTypes());
  }, [dispatch]);

  const availableTransportationOrdersLocations = useAppSelector(
    selectAvailableTransportationOrderLocations,
  );

  const availableTransportationOrdersStatus = useAppSelector(
    selectAvailableTransportationOrderStatus,
  );

  const availableTransportationOrdersTypes = useAppSelector(
    selectAvailableTransportationOrderTypes,
  );

  const availableTransportationOrdersTransportTypes = useAppSelector(
    selectAvailableTransportationOrderTransportTypes,
  );

  useEffect(() => {
    if (availableTransportationOrdersLocations) {
      setOrderLocations(availableTransportationOrdersLocations);
    }
  }, [availableTransportationOrdersLocations]);

  useEffect(() => {
    if (availableTransportationOrdersStatus) {
      setOrderStatus(availableTransportationOrdersStatus);
    }
  }, [availableTransportationOrdersStatus]);

  useEffect(() => {
    if (availableTransportationOrdersTypes) {
      setOrderTypes(availableTransportationOrdersTypes);
    }
  }, [availableTransportationOrdersTypes]);

  useEffect(() => {
    if (availableTransportationOrdersTransportTypes) {
      setOrderTransportTypes(availableTransportationOrdersTransportTypes);
    }
  }, [availableTransportationOrdersTransportTypes]);

  const [model, setModel] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [size, setSize] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [locality, setLocality] = useState('');
  const [region, setRegion] = useState('');
  const [country, setCountry] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [notes, setNotes] = useState('');
  const [transportNotes, setTransportNotes] = useState('');
  const [priority, setPriority] = useState('');
  const [deliveryNote, setDeliveryNote] = useState(false);
  const [contactDate, setContactDate] = useState<Date|undefined>();
  const [availability, setAvailability] = useState<string[]>([]);
  const [transportDate, setTransportDate] = useState<Date|undefined>();
  const [paymentPart, setPaymentPart] = useState(false);
  const [location, setLocation] = useState<TransportationOrderLocation|null>(null);
  const [status, setStatus] = useState<TransportationOrderStatus|null>(null);
  const [type, setType] = useState<TransportationOrderType|null>(null);
  const [transportType, setTransportType] = (
    useState<TransportationOrderTransportType|null>(null)
  );

  useEffect(() => {
    if (transportationOrder) {
      setModel(transportationOrder.model);
      setLicensePlate(transportationOrder.license_plate);
      setType(transportationOrder.type);
      setSize(transportationOrder.size);
      setAddressLine(transportationOrder.address.address_line);
      setPostalCode(transportationOrder.address.postal_code);
      setLocality(transportationOrder.address.locality);
      setRegion(transportationOrder.address.region);
      setCountry(transportationOrder.address.country);
      setName(transportationOrder.name);
      setPhone(transportationOrder.phone);
      setNotes(transportationOrder.notes);
      setTransportNotes(transportationOrder.transport_notes);
      setPriority(transportationOrder.priority);
      setDeliveryNote(transportationOrder.delivery_note);
      setContactDate(moment(transportationOrder.contact_date, DateFormat).toDate());
      setAvailability(transportationOrder.availability ? transportationOrder.availability.split(',') : []);
      setTransportDate(moment(transportationOrder.transport_date, DateFormat).toDate());
      setTransportType(transportationOrder.transport_type);
      setPaymentPart(transportationOrder.payment_part);
      setStatus(transportationOrder.status);
      setLocation(transportationOrder.location);
    }
  }, [transportationOrder]);

  const handleModel = (e:any) => setModel(e.target.value);
  const handleLicensePlate = (e: any) => setLicensePlate(e.target.value);
  const handleType = (e: any) => setType(e.target.value);
  const handleSize = (e: any) => setSize(e.target.value);
  const handleAddressLine = (e: any) => setAddressLine(e.target.value);
  const handlePostalCode = (e: any) => setPostalCode(e.target.value);
  const handleLocality = (e: any) => setLocality(e.target.value);
  const handleRegion = (e: any) => setRegion(e.target.value);
  const handleCountry = (e: any) => setCountry(e.target.value);
  const handleName = (e: any) => setName(e.target.value);
  const handlePhone = (e: any) => setPhone(e.target.value);
  const handleNotes = (e: any) => setNotes(e.target.value);
  const handleTransportNotes = (e: any) => setTransportNotes(e.target.value);
  const handlePriority = (e: any) => setPriority(e.target.value);
  const handleDeliveryNote = (e: any) => setDeliveryNote(e.target.checked);
  const handleContactDate = (e: any) => setContactDate(e.target.value);
  const handleAvailability = (e: any) => setAvailability(e.target.value);
  const handleTransportDate = (e: any) => setTransportDate(e.target.value);
  const handleTransportType = (e: any) => setTransportType(e.target.value);
  const handlePaymentPart = (e: any) => setPaymentPart(e.target.checked);
  const handleStatus = (e: any) => setStatus(e.target.value);
  const handleLocation = (e: any) => setLocation(e.target.value);

  const handleClick = () => {
    if (id && id !== 'add') {
      dispatch(
        updateTransportationOrder({
          id,
          model,
          license_plate: licensePlate,
          size,
          address_line: addressLine,
          postal_code: postalCode,
          locality,
          region,
          country,
          name,
          phone,
          notes,
          transport_notes: transportNotes,
          priority,
          delivery_note: deliveryNote,
          contact_date: moment(contactDate).isValid()
            ? moment(contactDate).format(DateFormat) : null,
          availability: availability.toString(),
          transport_date: moment(transportDate).isValid()
            ? moment(transportDate).format(DateFormat) : null,
          payment_part: paymentPart,
          status_id: status ? status.id : null,
          location_id: location ? location.id : null,
          type_id: type ? type.id : null,
          transport_type_id: transportType ? transportType.id : null,
        }),
      );
    } else if (id === 'add') {
      dispatch(
        createTransportationOrder({
          model,
          license_plate: licensePlate,
          size,
          address_line: addressLine,
          postal_code: postalCode,
          locality,
          region,
          country,
          name,
          phone,
          notes,
          transport_notes: transportNotes,
          priority,
          delivery_note: deliveryNote,
          contact_date: contactDate
            ? moment(contactDate).format(DateFormat) : null,
          availability: availability.toString(),
          transport_date: transportDate
            ? moment(transportDate).format(DateFormat) : null,
          payment_part: paymentPart,
          status_id: status ? status.id : null,
          location_id: location ? location.id : null,
          type_id: type ? type.id : null,
          transport_type_id: transportType ? transportType.id : null,
        }),
      );
    }
  };

  const isVisible = true;
  const setVisible = () => {
    dispatch(resetUpdateTransportationOrderLifecycle({ id }));
    navigate('/transportation-orders');
  };

  const getInvalidFieldClass = (field: string) => {
    const { form_errors } = id === 'add' ? (createLifecycle as Rejected || {}) : (updateLifecycle[id] as Rejected || {});
    return form_errors && form_errors[field] ? 'w-12 p-invalid' : 'w-12';
  };

  const renderInvalidFieldMessage = (field:string) => {
    const { form_errors } = id === 'add' ? (createLifecycle as Rejected || {}) : (updateLifecycle[id] as Rejected || {});

    return form_errors && form_errors[field] ? (
      form_errors[field].map((error: { message: string; code: string;}) => (
        <div key={field} className="invalid-form-input-message">
          {error.message}
        </div>
      ))
    ) : null;
  };
  return (

    <div>
      <Sidebar
        data-testid="edit-transportation-order-sidebar"
        visible={isVisible}
        position="right"
        className="p-sidebar-md"
        onHide={() => setVisible()}
        style={{ backgroundColor: '#F2F4F6' }}
        blockScroll
      >
        <h2 data-testid="transportation-order-sidebar-title" className="font-bold mt-4 mb-0">
          {id === 'add' ? 'Crear orden de transporte' : 'Editar orden de transporte'}
        </h2>

        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-model-input"
                className={getInvalidFieldClass('model')}
                type="text"
                id="modelInput"
                onChange={handleModel}
                value={model}
              />
              <label htmlFor="modelInput">Modelo</label>
            </span>
            {renderInvalidFieldMessage('model')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-license-plate-input"
                className={getInvalidFieldClass('license_plate')}
                type="text"
                id="licensePlateInput"
                onChange={handleLicensePlate}
                value={licensePlate}
              />
              <label htmlFor="licensePlateInput">Matrícula</label>
            </span>
            {renderInvalidFieldMessage('license_plate')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <Dropdown
                data-testid="transportation-order-type-input"
                className="custom-multiselect"
                id="typeInput"
                optionLabel="description"
                value={type}
                options={transportationOrderTypes}
                onChange={handleType}
              />
              <label htmlFor="typeInput">Tipo</label>
            </span>
            {renderInvalidFieldMessage('type_id')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-size-input"
                className={getInvalidFieldClass('size')}
                type="text"
                id="sizeInput"
                onChange={handleSize}
                value={size}
              />
              <label htmlFor="sizeInput">Tamaño</label>
            </span>
            {renderInvalidFieldMessage('size')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-address-line-input"
                className={getInvalidFieldClass('address_line')}
                type="text"
                id="addressLineInput"
                onChange={handleAddressLine}
                value={addressLine}
              />
              <label htmlFor="addressLineInput">Dirección</label>
            </span>
            {renderInvalidFieldMessage('address_line')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-postal-code-input"
                className={getInvalidFieldClass('postal_code')}
                type="text"
                id="postalCodeInput"
                onChange={handlePostalCode}
                value={postalCode}
              />
              <label htmlFor="postalCodeInput">Código postal</label>
            </span>
            {renderInvalidFieldMessage('postal_code')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-locality-input"
                className={getInvalidFieldClass('locality')}
                type="text"
                id="localityInput"
                onChange={handleLocality}
                value={locality}
              />
              <label htmlFor="localityInput">Localidad</label>
            </span>
            {renderInvalidFieldMessage('locality')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-region-input"
                className={getInvalidFieldClass('region')}
                type="text"
                id="regionInput"
                onChange={handleRegion}
                value={region}
              />
              <label htmlFor="regionInput">Región</label>
            </span>
            {renderInvalidFieldMessage('region')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-country-input"
                className={getInvalidFieldClass('country')}
                type="text"
                id="countryInput"
                onChange={handleCountry}
                value={country}
              />
              <label htmlFor="countryInput">País</label>
            </span>
            {renderInvalidFieldMessage('country')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-name-input"
                className={getInvalidFieldClass('name')}
                type="text"
                id="nameInput"
                onChange={handleName}
                value={name}
              />
              <label htmlFor="nameInput">Nombre</label>
            </span>
            {renderInvalidFieldMessage('name')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-phone-input"
                className={getInvalidFieldClass('phone')}
                type="text"
                id="phoneInput"
                onChange={handlePhone}
                value={phone}
              />
              <label htmlFor="phoneInput">Teléfono</label>
            </span>
            {renderInvalidFieldMessage('phone')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-notes-input"
                className={getInvalidFieldClass('notes')}
                type="text"
                id="notesInput"
                onChange={handleNotes}
                value={notes}
              />
              <label htmlFor="notesInput">Notas</label>
            </span>
            {renderInvalidFieldMessage('notes')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-transport-notes-input"
                className={getInvalidFieldClass('transport_notes')}
                type="text"
                id="transportNotesInput"
                onChange={handleTransportNotes}
                value={transportNotes}
              />
              <label htmlFor="transportNotesInput">Notas de transporte</label>
            </span>
            {renderInvalidFieldMessage('transport_notes')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <InputText
                data-testid="transportation-order-priority-input"
                className={getInvalidFieldClass('priority')}
                type="text"
                id="priorityInput"
                onChange={handlePriority}
                value={priority}
              />
              <label htmlFor="priorityInput">Prioridad</label>
            </span>
            {renderInvalidFieldMessage('priority')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <Checkbox
                data-testid="transportation-order-delivery-note-input"
                onChange={handleDeliveryNote}
                checked={deliveryNote}
                id="deliveryNoteInput"
              />
              <label htmlFor="deliveryNoteInput" className="ml-4">Albarán</label>
            </span>
            {renderInvalidFieldMessage('delivery_note')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <Calendar
                data-testid="transportation-order-contact-date-input"
                className={getInvalidFieldClass('contact_date')}
                id="contactDateInput"
                onChange={handleContactDate}
                value={contactDate}
                dateFormat="dd-mm-yy"
              />
              <label htmlFor="contactDateInput">Fecha contacto</label>
            </span>
            {renderInvalidFieldMessage('contact_date')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <MultiSelect
                data-testid="edit-carrier-availability-input"
                className="custom-multiselect"
                id="availabilityInput"
                value={availability}
                options={availableDays}
                onChange={handleAvailability}
                maxSelectedLabels={3}
              />
              <label htmlFor="availabilityInput">Disponibilidad</label>
            </span>
            {renderInvalidFieldMessage('availability')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <Calendar
                data-testid="transportation-order-transport-date-input"
                className={getInvalidFieldClass('transport_date')}
                id="transportDateInput"
                onChange={handleTransportDate}
                value={transportDate}
                dateFormat="dd-mm-yy"
                disabled
              />
              <label htmlFor="transportDateInput">Fecha transporte</label>
            </span>
            {renderInvalidFieldMessage('transport_date')}
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <Dropdown
                data-testid="transportation-order-transport-type-input"
                className="custom-multiselect"
                id="transportTypeInput"
                optionLabel="description"
                value={transportType}
                options={transportationOrderTransportTypes}
                onChange={handleTransportType}
              />
              <label htmlFor="transportTypeInput">Tipo de transporte</label>
            </span>
            {renderInvalidFieldMessage('transport_type_id')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <Checkbox
                data-testid="transportation-order-payment-part-input"
                onChange={handlePaymentPart}
                checked={paymentPart}
                id="paymentPartInput"
              />
              <label htmlFor="paymentPartInput" className="ml-4">Parte pago</label>
            </span>
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 mb-6 w-6 mr-2">
            <span className="p-float-label">
              <Dropdown
                data-testid="edit-status-input"
                className="custom-multiselect"
                id="statusInput"
                optionLabel="description"
                value={status}
                options={transportationOrderStatus}
                onChange={handleStatus}
              />
              <label htmlFor="statusInput">Estado</label>
            </span>
            {renderInvalidFieldMessage('status_id')}
          </div>
          <div className="mt-6 w-6 mr-2">
            <span className="p-float-label">
              <Dropdown
                data-testid="edit-location-input"
                className="custom-multiselect"
                id="locationInput"
                optionLabel="description"
                value={location}
                options={transportationOrderLocations}
                onChange={handleLocation}
              />
              <label htmlFor="locationInput">Ubicación</label>
            </span>
            {renderInvalidFieldMessage('location_id')}
          </div>
        </div>

        <div className="w-12 flex justify-content-end mt-6">
          <Button
            data-testid="transportation-order-form-submit-button"
            label={id === 'add' ? 'Crear' : 'Editar'}
            icon="pi pi-upload"
            onClick={handleClick}
            loading={id === 'add' ? createLifecycle === ComponentLifecycle.FETCHING_DATA : updateLifecycle[id] === ComponentLifecycle.FETCHING_DATA}
          />
        </div>
      </Sidebar>
    </div>
  );
}
