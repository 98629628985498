import axios from '../../app/axiosInterceptor';
import { CreateRoute, RoutePost } from './routeTypes';

export function listRoutesAPI() {
  return axios({
    method: 'get',
    url: 'list-routes',
  });
}

export function listRouteOrdersAPI(routeId: string) {
  return axios({
    method: 'post',
    url: 'list-orders-from-routes',
    data: { id: routeId },
  });
}

export function getRoutesByLicensePlateAPI(licensePlate: string) {
  return axios({
    method: 'post',
    url: 'get-routes-by-license-plate',
    data: { license_plate: licensePlate },
  });
}

export function createRouteAPI(route: CreateRoute) {
  return axios({
    method: 'post',
    url: 'create-route',
    data: route,
  });
}

export function updateRouteAPI(route: RoutePost) {
  return axios({
    method: 'post',
    url: 'update-route',
    data: route,
  });
}

export function deleteRouteAPI(routeId: string) {
  return axios({
    method: 'post',
    url: 'delete-route',
    data: { id: routeId },
  });
}
